import styled, { keyframes } from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Inner = styled.div`
  width: 100%;
  max-width: 600px;
`

export const JobTitleHeader = styled.div`
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.25rem;
`

export const DescriptionFieldLabel = styled.div<{ $isDisabled: boolean }>`
  opacity: ${({ $isDisabled }) => $isDisabled ? 0.5 : 1};
`

export const DescriptionField = styled.div<{ $isDisabled: boolean }>`
  border-radius: 0.25rem;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  textarea {
    border: none;
    &:focus {
      outline: none;
    }
  }
  ${({ $isDisabled }) => $isDisabled && `
    pointer-events: none;
    opacity: 0.5;
  `}
`

export const DescriptionFieldActions = styled.div<{ $hasGradient: boolean }>`
  display: flex;
  align-items: center;
  /* height: 3.5rem; */
  gap: 0.75rem;
  margin-top: 1rem;
  overflow: hidden;
  background: ${({ theme, $hasGradient }) => $hasGradient ? theme.backgrounds.aiGradientFaded : 'none'};
`

export const ImportJobInput = styled.div<{ $isLoading: boolean, $isDisabled: boolean, $isError: boolean }>`
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.25rem;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  padding: 0.25rem 0.25rem 0.25rem 0.625rem;
  pointer-events: ${({ $isLoading }) => ($isLoading ? 'none' : 'all')};
  margin-bottom: ${({ $isError }) => $isError ? '0' : '2rem'};
  svg {
    flex-grow: 0;
    flex-shrink: 0;
    opacity: ${({ $isLoading, $isDisabled }) => ($isLoading || $isDisabled ? 0.5 : 1)};
  }
  input {
    width: 100%;
    flex: 1 1 100%;
    color: ${({ theme }) => theme.colors.fgPrimary};
    font-size: ${({ theme }) => theme.fontSizes[14]};
    line-height: 1;
    border: none;
    outline: none;
    background-color: transparent;
    opacity: ${({ $isLoading, $isDisabled }) => ($isLoading || $isDisabled ? 0.5 : 1)};
    pointer-events: ${({ $isLoading }) => ($isLoading ? 'none' : 'all')};
  }
`

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.55; }
  100% { opacity: 1; }
`

export const Generating = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  border-top: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  svg {
    path {
      fill: #455FF0;
    }
  }
  p {
    span {
      font-size: ${({ theme }) => theme.fontSizes['14']};
      animation: 1.5s ${blink} ease-in-out infinite;
      &:nth-child(1) {
        background: linear-gradient(90deg, #9F34DD, #6A54E3);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &:nth-child(2) {
        background: linear-gradient(90deg, #6A54E3, #764FE2);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation-delay: 0.22s;
      }
      &:nth-child(3) {
        background: linear-gradient(90deg, #764FE2, #9A3FDB);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation-delay: 0.44s;
      }
    }
  }
`

export const SuggestionsBox = styled.div`
  padding: 0.75rem;
  border-top: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const Suggestions = styled.div`
  display: flex;
  gap: 0.375rem;
  flex-wrap: wrap;
`

export const Suggestion = styled.button`
  border: none;
  height: 1.25rem;
  padding: 0.125rem 0.35rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: 4px;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.aiTranslucent10};
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  span {
    font-size: ${({ theme }) => theme.fontSizes[12]};
    font-weight: 500;
    letter-spacing: 0.12px;
    line-height: 1rem;
    background: linear-gradient(267deg, #8B20C9 -0.11%, #1D37C8 99.81%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.aiTranslucent10};
    }
  }
`
