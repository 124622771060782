import { useEffect, useState } from 'react'
import { EditorContent, useEditor } from '@tiptap/react'
import type { Editor as TiptapEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import { mergeAttributes } from '@tiptap/core'
import * as S from './subject-editor.styled'
import { deserializeEditorContent } from './serializers'
import VariableHighlighter from './extensions/variableHighlighter'
import { useTheme } from 'styled-components'

export interface SubjectEditorProps {
  isEditable?: boolean
  initialContent?: string | null
  onDataChanged: (data: string) => void
  forceEditorFocus?: boolean
  setEditor?: (editor: TiptapEditor | null) => void
  onBlur?: () => void
  content: string | null | undefined
}

export const SubjectEditor = ({
  isEditable = true,
  initialContent,
  onDataChanged,
  forceEditorFocus = false,
  setEditor,
  onBlur,
  content
}: SubjectEditorProps): JSX.Element => {
  const { colors } = useTheme()
  const [initialized, setInitialized] = useState(false)

  const editor = useEditor({
    onUpdate: ({ editor }) => {
      const output = editor.getText()
      onDataChanged(output)
    },
    onBlur: () => {
      onBlur?.()
    },
    extensions: [
      StarterKit.configure({
        paragraph: false,
        dropcursor: {
          width: 3,
          color: colors.tintBg
        }
      }),
      // This converts using <div> instead of <p> for paragraphs
      Paragraph.extend({
        parseHTML () {
          return [{ tag: 'p' }, { tag: 'div' }]
        },
        renderHTML ({ HTMLAttributes }) {
          return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0] // eslint-disable-line
        }
      }),
      Link.configure({
        openOnClick: false,
        linkOnPaste: true,
        HTMLAttributes: {
          class: 'editor-link'
        }
      }),
      Placeholder.configure({
        placeholder: 'Your subject line...'
      }),
      VariableHighlighter
    ],
    editable: false, // false by default
    content
  })

  useEffect(() => {
    if (!editor) {
      return
    }
    editor.setEditable(isEditable)
  }, [isEditable, editor])

  useEffect(() => {
    if (!editor) {
      return
    }

    if (((isEditable && !initialized) || !isEditable) && initialContent && initialContent?.length >= 1) {
      setInitialized(true)
      editor.commands.setContent(deserializeEditorContent(initialContent))
    }
  }, [editor, initialContent, initialized, isEditable])

  useEffect(() => {
    if (forceEditorFocus) {
      setTimeout(() => {
        editor?.commands.focus('end')
      }, 100)
    }
  }, [forceEditorFocus, editor])

  useEffect(() => {
    setEditor?.(editor)
    return () => {
      setEditor?.(null)
      editor?.destroy()
    }
  }, [editor, setEditor])

  return (
    <S.Editor $isEditable={isEditable}>
      <S.EditorContent
        $padding='6px 0px'
        $editorHeight='100%'
        $isEditable={isEditable}
        $isEmpty={!!editor?.isEmpty}
      >
        <EditorContent editor={editor} />
      </S.EditorContent>
    </S.Editor>
  )
}
