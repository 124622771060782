import styled, { css, keyframes } from 'styled-components'
import type {
  SectionVariant,
  SectionStyleProps,
  CandidateSummaryStyleProps
} from './candidate-details'
import type { Color, Spacing } from 'src/styles/theme/types'

interface SectionProps extends SectionStyleProps {
  $variant: SectionVariant
}

export const Section = styled.div<SectionProps>`
  width: 100%;
  ${({ $variant, theme }) =>
    $variant === 'plain' &&
    `
      border: solid 1px;
      border-color: ${theme.colors.bgTertiary};
      background-color: ${theme.colors.bgSecondary};
      border-radius: 6px;
      padding: 0.75rem;
  `}
  ${({ $variant, $padding, theme }) =>
    $variant === 'ghost' &&
    `
    header {
      padding-left: ${theme.spacing[$padding?.left ?? 24]};
    }
  `}
`

export const SectionHeader = styled.header<{ $isCollapsible: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${({ $isCollapsible }) =>
    $isCollapsible &&
    `
    &:focus {
      outline-offset: 0.5rem;
    }
  `}
`

export const Profile = styled.div<{ $gap?: Spacing }>`
  display: flex;
  flex: 1 1 50%;
  gap: ${({ theme, $gap }) => theme.spacing[$gap ?? 16]};
  width: 100%;
  align-items: center;
`

export const ProfileContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.125rem;
  flex: 1 1 auto;
  overflow: hidden;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 3px;
  }
`

export const ProfileInner = styled.div<{ $verticalGap?: Spacing }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme, $verticalGap }) => theme.spacing[$verticalGap ?? 6]};
`

export const Insights = styled.div`
  padding: 0;

  highlight {
    /* background-color: ${({ theme }) => theme.colors.warningTranslucent25}; */
    padding: 1.5px 4px;
    border-radius: 4px;
    white-space: nowrap;
    background: linear-gradient(267deg, rgba(206, 150, 239, 0.25) -0.11%, rgba(130, 146, 240, 0.25) 99.81%);
  }
`

export const Empty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  p {
    font-size: ${({ theme }) => theme.fontSizes[12]};
    color: ${({ theme }) => theme.colors.fgTertiary};
    font-weight: ${({ theme }) => theme.fontWeights[500]};
  }
`

export const CandidateSkills = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  list-style-type: none;
`

export const CandidateInfoTag = styled.li`
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  padding: 0 6px;
  padding-right: 3px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  div>p {
    line-height: 1.43;
    transform: translateY(5%);
  }
`

export const CandidateInfoTagLeadingIcon = styled.span`
  display: inline-flex;
  transform: translateY(1.75px);
  padding-right: 0.125rem;
`

export const SkillExperience = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: 2px;
  padding: 0.25rem;
  height: calc(100% - 0.25rem);
`

export const CandidateSummary = styled.div<CandidateSummaryStyleProps>`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1rem 1.5rem;
  padding-top: ${({ theme, $padding }) => theme.spacing[$padding?.top ?? 16]};
  padding-right: ${({ theme, $padding }) => theme.spacing[$padding?.right ?? 24]};
  padding-bottom: ${({ theme, $padding }) => theme.spacing[$padding?.bottom ?? 16]};
  padding-left: ${({ theme, $padding }) => theme.spacing[$padding?.left ?? 24]};
`

export const CandidateQuickOverview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1rem 1rem 0.25rem 1.5rem;
`

export const QuickOverviewRowIcon = styled.div`
  grid-column: 1;
  /* Optical alignment */
  transform: translateY(-1px);
`

export const QuickOverviewRow = styled.div`
  display: grid;
  grid-template-columns: 1rem 1fr;
  grid-column-gap: 0.5rem;
  &:first-child {
    ${QuickOverviewRowIcon} {
      /* Optical alignment */
      transform: translateY(-2px);
    }
  }
`

export const QuickOverviewDetails = styled.div`
  grid-column: 2;
`

export const QuickOverviewCompanyBadge = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  &:not(:last-of-type) {
    >span {
      &:after {
        content: ', ';
      }
    }
  }
`

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

export const MatchedCriteriaContainer = styled.div<{ $hovered: boolean }>`
  ${({ $hovered, theme }) => $hovered
    ? css`
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing[20]};
      padding: ${theme.spacing[16]};
      border-radius: ${theme.spacing[8]};
      border: 1px solid ${theme.colors.borderTranslucent};
      background-color: ${theme.colors.bgPrimary};
      box-shadow: ${theme.boxShadows.md};
      z-index: 20;
      position: absolute;
      margin-top: -37px;
      margin-left: -17px;
      animation: ${fadeIn} 0.3s;
    `
  : `
      display: none;
    `
}
`

export const MatchedCriteria = styled.div<{ $bg: Color }>`
  display: flex;
  gap: ${({ theme }) => theme.spacing[4]};
  height: 20px;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[4]}`};
  border-radius: ${({ theme }) => theme.spacing[4]};
  background-color: ${({ theme, $bg }) => theme.colors[$bg ?? 'tintFaded']};
  width: fit-content;
  cursor: pointer;
`

export const MatchedContent = styled.div`
  padding: ${({ theme }) => `9 ${theme.spacing[4]}`};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[6]};
`

export const IconContainer = styled.div`
  width: ${({ theme }) => theme.spacing[12]};
  height: ${({ theme }) => theme.spacing[12]};
  display: flex;
  align-items: center;
  justify-content: center;
  /* For optical alignment */
  transform: translateX(3px);
`
