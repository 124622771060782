import { useLayoutEffect, useState, useRef, useMemo } from 'react'
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import type { AccessorKeyColumnDef, RowData } from '@tanstack/react-table'
import * as S from './tabular-chart.styled'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives/flex'
import { EmptyChart } from '../empty-chart'
import { ChartHeader } from '../chart-header'

interface TabularChartProps<T> {
  schema: Record<string, string | React.ReactNode>
  tableData: T[]
  calculatePercentage?: boolean
  columnSizes?: Record<string, number>
}

export type TabularChartSchema<T> = {
  [K in keyof T]: string | React.ReactNode
}

export const TabularChart = <T extends object>({
  schema,
  tableData,
  calculatePercentage = false,
  columnSizes
}: TabularChartProps<T>): JSX.Element => {
  const tableRef = useRef<HTMLTableElement>(null)

  const columnTotals = useMemo(() => {
    const totals: Record<string, number> = {}
    tableData.forEach((row) => {
      const keys = Object.keys(row) as Array<keyof T>
      keys.forEach((key) => {
        const value = row[key]
        if (typeof value === 'number') {
          totals[key as string] = (totals[key as string] || 0) + value
        }
      })
    })
    return totals
  }, [tableData])

  const createAccessorColumn = <TData extends RowData>(
    key: keyof TData,
    header: string | React.ReactNode
  ): AccessorKeyColumnDef<TData, unknown> => {
    return {
      accessorKey: key,
      header: () => <>{header}</>,
      cell: (info) => {
        const value = info.getValue()
        if (calculatePercentage && typeof value === 'number' && columnTotals[key as number] > 0) {
          const percentage = Math.round((value / columnTotals[key as number]) * 100)
          return (
            <Flex $gap={4} $align="center" $justify='center'>
              <Caption size="SM" $color="fgSecondary" $fontWeight={400}>
                {value}
              </Caption>
              <Paragraph size="XS" $color="fgTertiary">
                ({percentage}%)
              </Paragraph>
            </Flex>
          )
        }
        return <>{value}</>
      }
    }
  }

  const columns = [
    ...Object.keys(schema).map((key) => createAccessorColumn(key as keyof T, schema[key]))
  ]

  const [data, setData] = useState(() => [...(tableData || [])])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  useLayoutEffect(() => {
    setData([...(tableData || [])])
    if (tableRef?.current) {
      const tableWidth = tableRef.current.offsetWidth
      const tableColumns = 7
      const actualColumns = Object.keys(schema).length
      const columnSpanForFirstColumn = tableColumns - actualColumns + 1
      const defaultColumnSize = tableWidth / tableColumns
      const columnSizes: Record<string, number> = {}
      columnSizes[Object.keys(schema)[0]] = defaultColumnSize * columnSpanForFirstColumn

      Object.keys(schema).forEach((key, index) => {
        if (index !== 0) {
          columnSizes[key] = defaultColumnSize
        }
      })

      table.setColumnSizing(columnSizes)
    }
  }, [table, tableData, columnSizes, schema])

  if (!tableData.length) {
    const title = schema[Object.keys(schema)[0]] as string
    return (
      <>
        <ChartHeader title={title} />
        <EmptyChart $minHeight='80px' />
      </>
    )
  }

  return (
    <S.TabularChart>
      <S.Table ref={tableRef} >
        <S.Head>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  {...{
                    key: header.id,
                    colSpan: header.colSpan,
                    style: {
                      width: header.getSize()
                    }
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </S.Head>
        <S.Body>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </S.Body>
      </S.Table>
    </S.TabularChart>
  )
}
