import { useDialog } from 'src/hooks/use-dialog'
import * as Dialog from 'src/components/primitives/dialog'
import { EmailComposer } from 'src/components/blocks/email-composer'
import { Button } from 'src/components/primitives/button'
import { useState } from 'react'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import type { Spacing } from 'src/styles/theme/types'
import { DialogId } from 'src/contexts/dialogs'
import { useBuildCustomOutreach } from 'src/hooks/queries/use-build-custom-outreach'
import { useSendCustomOutreach } from 'src/hooks/mutations/use-send-custom-outreach'
import type { CustomOutreachEmail } from 'src/libs/api/backend/custom_outreach'

interface WriteCustomOutreachDialogProps {
  candidateJob: CandidateJobExpanded
}

export const WriteCustomOutreachDialog = ({
  candidateJob
}: WriteCustomOutreachDialogProps): JSX.Element => {
  const { isDialogOpen, controlDialog, openAlert } = useDialog()
  const [customOutreachEmail, setCustomOutreachEmail] = useState<CustomOutreachEmail>({
    subject: '',
    body: ''
  })

  const [isSendable, setIsSendable] = useState(false)

  const { data: buildCustomOutreach, isPending: isBuildCustomOutreachLoading } = useBuildCustomOutreach({
    candidateJobId: candidateJob.id
  })

  const { sendCustomOutreach } = useSendCustomOutreach()

  // useEffect(() => {
  //   const invalidStatus = [
  //     CandidateJobStatus.EMAIL_NOT_FOUND,
  //     CandidateJobStatus.BOUNCED,
  //     CandidateJobStatus.SEARCHING_FOR_EMAIL
  //   ]
  //   const validCandidateIds = candidateJobs
  //     ?.filter(
  //       (candidateJob) =>
  //         candidateJob.statusDisplay?.status &&
  //         !invalidStatus.includes(candidateJob.statusDisplay?.status)
  //     )
  //     .map((candidateJob) => candidateJob.candidateId)
  //   if (validCandidateIds) {
  //     setCustomOutreachEmail((prev) => ({
  //       ...prev,
  //       candidateIds: validCandidateIds
  //     }))
  //   }
  // }, [candidateJobs])

  const handleCloseDialog = (): void => {
    const userHasChanges = (): boolean => {
      // Ideally, we'd use something like `isEqual` here, but depending on the
      // use case and how the editor updates, it might return an empty <p> tag
      // which would return true, even if there have not been any "real" changes.
      const detectChanges = (input: string): boolean => {
        return input !== '<p></p>' && input !== '<p> </p>' && input !== ''
      }
      const bodyHasChanges = detectChanges(customOutreachEmail.body)
      const subjectHasChanges = detectChanges(customOutreachEmail.subject ?? '')
      return bodyHasChanges || subjectHasChanges
    }

    if (userHasChanges()) {
      openAlert({
        message: 'Are you sure you want to leave this page?',
        description: 'All your changes will be lost.',
        cancelText: 'Stay on page',
        confirmText: 'Discard and leave',
        onConfirm: () => {
          controlDialog(DialogId.WRITE_CUSTOM_OUTREACH, false)
        }
      })
    } else {
      controlDialog(DialogId.WRITE_CUSTOM_OUTREACH, false)
    }
  }

  return (
    <Dialog.Root
      id={DialogId.WRITE_CUSTOM_OUTREACH}
      isOpen={isDialogOpen(DialogId.WRITE_CUSTOM_OUTREACH)}
      onOpenChange={() => {
        handleCloseDialog()
      }}
      $width='half'
      $maxWidth="640px"
      $innerPadding={{
        top: 0 as Spacing,
        right: 0 as Spacing,
        bottom: 0 as Spacing,
        left: 0 as Spacing
      }}
    >
      <Dialog.Portal>
        <Dialog.Header
          title="1st Outreach Email Personalization"
          onClose={() => {
            handleCloseDialog()
          }}
        />
        <Dialog.Content>
          <EmailComposer
            isGenerating={isBuildCustomOutreachLoading}
            initialEmailBody={buildCustomOutreach?.emailMessageContent.body.html}
            subject={buildCustomOutreach?.emailMessageContent.subject}
            body={buildCustomOutreach?.emailMessageContent.body.html}
            useCcFields={false}
            forceEditorFocus
            disableEmailAccountSelection
            sendingUserId={buildCustomOutreach?.sendingUserId}
            sendingEmailAccountId={buildCustomOutreach?.sendingEmailAccountId}
            recipients={[candidateJob]}
            onSendableStateChange={(sendable) => {
              setIsSendable(sendable)
            }}
            onDataChanged={(data) => {
              const isEmptyChange = data.subject === '' && (data.body === '<div></div>' || data.body === '')
              if (isEmptyChange) {
                return
              }

              const updated = {
                ...customOutreachEmail,
                subject: data.subject ?? '',
                body: data.body ?? ''
              }
              setCustomOutreachEmail(updated)
            }}
            minHeight="8rem"
            $maxHeight='calc(100vh - 40rem)'
            $editorHeight='fit-content'
            useVariables={false}
            leftActions={
              <Button
                $variant="fill"
                $colorTheme="tint"
                $height={32}
                disabled={!isSendable}
                onClick={() => {
                  sendCustomOutreach({
                    sendCustomOutreachParams: {
                      candidateJobId: candidateJob.id,
                      emailContent: {
                        subject: customOutreachEmail.subject ?? '',
                        body: customOutreachEmail.body
                      }
                    }
                  })
                }}
              >
                Start outreach
              </Button>
            }
          />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
