import { Form } from 'src/components/forms/form'
import * as S from './create-new-job-form.styled'
import { Select } from 'src/components/forms/select'
import type { SelectItem } from 'src/components/forms/select'
import { useDialog } from 'src/hooks/use-dialog'
import { useGetDepartmentQuery } from 'src/hooks/queries/use-departments'
import { useForm } from 'src/hooks/use-form'
import { isNil } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DialogId } from 'src/contexts/dialogs'
import { DepartmentLogo } from '../department-logo'
import { useSession } from 'src/hooks/use-session'
import { Avatar } from 'src/components/primitives/avatar'
import { Input } from 'src/components/forms/input'
import { Textarea } from 'src/components/forms/textarea'
import { Button } from 'src/components/primitives/button'
import { Spacer } from 'src/components/primitives/spacer'
import { FieldLabel } from 'src/components/forms/field-label'
import { When } from '../when'
import { Flex } from 'src/components/primitives/flex'
import { Icon } from 'src/components/primitives/icon'
import { Combobox } from 'src/components/forms/combobox'
import type { ComboboxOption } from 'src/components/forms/combobox'
import { useJobListings } from 'src/hooks/queries/use-job-listings'
import { newJobParser } from 'src/libs/api/backend/jobs'
import type { NewJob } from 'src/libs/api/backend/jobs'
import type { JobBoardListing } from 'src/libs/api/backend/external_job_listings'
import { Spinner } from 'src/components/primitives/spinner'
import RouteBuilder from 'src/libs/route-builder'
import { useNavigate } from 'react-router'
import { useCreateNewJob } from 'src/hooks/mutations/use-create-new-job'
import { extractIdealCandidate, extractJobRequirements } from 'src/libs/api/backend/gpt'
import type { ExtractJobRequirementsInput } from 'src/libs/api/backend/gpt'
import { Caption } from 'src/components/primitives/typography'
import { DEFAULT_REFINEMENT_SUGGESTIONS } from 'src/libs/data'
import { FieldError } from 'src/components/forms/field-error'
import { Banner } from '../banner'
import { useNewJobSuggestedAdditions } from '../../../hooks/queries/use-job-search-refinement-suggested-additions'

enum FormView {
  START = 'START',
  IMPORT_FROM_LINK = 'IMPORT_FROM_LINK',
  GENERATING = 'GENERATING'
}

interface CreateNewJobFormProps {
  redirectType?: 'onboarding' | 'jobs'
  onCreateJob?: () => void
}

interface JobPostLinkType {
  jobPostUrl: string | null
  inputIsFocused: boolean
  isImporting: boolean
  isError: boolean
  importedJob: ExtractJobRequirementsInput | null
}

export const CreateNewJobForm = ({
  redirectType = 'jobs',
  onCreateJob
}: CreateNewJobFormProps): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formView, setFormView] = useState<FormView>(FormView.START)
  const [formError, setFormError] = useState<string | null>(null)
  const [isLoadingJobsForSelectedDepartment, setIsLoadingJobsForSelectedDepartment] = useState(true)

  const [allImportedJobListings, setAllImportedJobListings] = useState<JobBoardListing[] | null>(
    null
  )
  const [importedJobsByDepartment, setImportedJobsByDepartment] = useState<ComboboxOption[] | null>(
    null
  )
  const [jobToImportFromLink, setJobToImportFromLink] = useState<JobPostLinkType>({
    jobPostUrl: null,
    inputIsFocused: false,
    isImporting: false,
    isError: false,
    importedJob: null
  })

  const navigate = useNavigate()
  const { createNewJob } = useCreateNewJob()
  const { openDialog, closeDialog } = useDialog()
  const { departments } = useGetDepartmentQuery()
  const { org, orgLogoUrl } = useSession()

  const importLinkInputEl = useRef<HTMLInputElement>(null)

  const { submit, register, setValue, formData, reset } = useForm<NewJob>({
    schema: newJobParser,
    initialValues: {
      departmentId: org?.id
    }
  })

  const isFormValid = useMemo(() => {
    return (
      !isNil(formData.title) &&
      formData.title !== '' &&
      !isNil(formData.description) &&
      formData.description !== ''
    )
  }, [formData.title, formData.description])

  const {
    data: importedJobs,
    isError: importedJobsError
  } = useJobListings({
    url: jobToImportFromLink?.jobPostUrl,
    domain: departments?.find((department) => department.id === formData?.departmentId)?.domain ?? org?.domain
  })

  // This hook does the main preparation work
  // * Prepares automatically imported jobs to be searchable in Combobox
  // * Updates the state when user imports a single job from a job board link (e.g. Greenhouse)
  //   so we then can let the backend extract the relevant information from it
  useEffect(() => {
    if (importedJobs) {
      if (!isNil(jobToImportFromLink?.jobPostUrl)) {
        const foundListing = importedJobs?.[0]
        if (foundListing) {
          setValue('title', foundListing.title)
          setJobToImportFromLink((prev) => ({
            ...prev,
            isError: false,
            importedJob: foundListing,
            jobPostUrl: null
          }))
        }
      } else {
        setIsLoadingJobsForSelectedDepartment(true)
        setAllImportedJobListings(importedJobs)

        const selectedDepartment =
          departments?.find((department) => department.id === formData?.departmentId)?.domain ??
          org?.domain

        const jobsByName = importedJobs
          .filter((job) => job.domain === selectedDepartment)
          .reduce<Record<string, JobBoardListing[]>>((acc, job) => {
          const name = job.name ?? ''
          acc[name] = acc[name] ? [...acc[name], job] : [job]
          return acc
        }, {})

        const comboboxOptions: ComboboxOption[] = Object.values(jobsByName).flatMap(
          (jobList: JobBoardListing[]) =>
            jobList.map((job) => {
              const department = departments?.find((dep) => dep.domain === job.domain)
              return {
                value: job.url ?? job.title,
                name: job.title,
                nameContext: job.location,
                symbol: <DepartmentLogo $size={12} departmentId={department?.id ?? ''} />,
                trailingAction: (
                  <Button
                    nested
                    $variant="raised"
                    $colorTheme="tint"
                    $height={24}
                    $width={50}
                    $align="center"
                    $fontSize={12}
                  >
                    Import
                  </Button>
                )
              }
            })
        )
        // We want to store this to not being replaced when a user
        // enters a url to import a job from, otherwise the Combobox
        // would then be empty.
        setImportedJobsByDepartment(comboboxOptions.slice(0, 300))
      }
    }
  }, [importedJobs, departments, jobToImportFromLink?.jobPostUrl, formData?.departmentId, org?.domain, setValue])

  useEffect(() => {
    if (importedJobs === undefined && importedJobsError) {
      setJobToImportFromLink(prev => ({
        ...prev,
        isError: true,
        isImporting: false,
        jobPostUrl: null,
        importedJob: null
      }))
      setFormView(FormView.IMPORT_FROM_LINK)
    }
  }, [importedJobs, importedJobsError])

  useEffect(() => {
    if (!isNil(importedJobsByDepartment) && importedJobsByDepartment.length > 0) {
      setTimeout(() => {
        setIsLoadingJobsForSelectedDepartment(false)
      }, 100)
    }
  }, [importedJobsByDepartment])

  const departmentItems = useMemo((): SelectItem[] => {
    if (isNil(org)) {
      return []
    }

    const orgListItem = {
      value: org.id,
      title: `${org.name} • ${org.domain}`,
      image: (
        <Avatar $type="logo" $size={16} $border={false} photoUrl={orgLogoUrl} initials={org.name} />
      )
    }
    const deptsListItems =
      departments
        ?.filter((department) => !department.deleted)
        .map((dept) => ({
          value: dept.id,
          title: `${dept.name} • ${dept.domain}`,
          image: <DepartmentLogo $size={16} departmentId={dept.id} />
        })) ?? []

    return [orgListItem, ...deptsListItems]
  }, [org, departments, orgLogoUrl])

  const handleCreateJob = useCallback(async (formData: NewJob): Promise<void> => {
    setIsSubmitting(true)
    const newJob = {
      ...formData,
      departmentId: !isNil(org) && formData.departmentId === org?.id ? null : formData.departmentId,
      title: formData.title ?? '',
      diverseHire: false,
      locations: formData?.locations,
      jobDescriptionRefinement: formData?.description,
      jobBoardDescription: jobToImportFromLink?.importedJob?.content ?? '',
      workspace: formData?.workspace
    }
    createNewJob({
      job: newJob,
      onSuccess: (createdJob) => {
        let redirectRoute = RouteBuilder.build('JOBS_CANDIDATES', { jobId: createdJob.id })
        if (redirectType === 'onboarding') {
          redirectRoute = RouteBuilder.build('ONBOARDING_CONNECTED_ACCOUNTS')
        }
        closeDialog(DialogId.CREATE_NEW_JOB)
        navigate(redirectRoute)
        setIsSubmitting(false)
        if (redirectType === 'jobs') {
          openDialog(DialogId.CREATE_SEQUENCE, 'OPTIONS')
        }
        onCreateJob?.()
      }
    })
  }, [org, jobToImportFromLink?.importedJob?.content, createNewJob, redirectType, closeDialog, navigate, onCreateJob, openDialog])

  useEffect(() => {
    if (formView === 'IMPORT_FROM_LINK') {
      setTimeout(() => {
        if (importLinkInputEl.current) {
          importLinkInputEl.current.focus()
        }
      }, 20)
    }
  }, [formView])

  const handleImportJobFromLink = async (): Promise<void> => {
    if (importLinkInputEl.current) {
      setValue('description', '')
      setValue('title', importLinkInputEl.current.value)
      setFormView(FormView.GENERATING)
      setJobToImportFromLink((prev) => ({
        ...prev,
        isImporting: true,
        jobPostUrl: importLinkInputEl?.current?.value ?? null
      }))
    }
  }

  const getJobSummaryFromImportedLink = useCallback(async (
    importedJob: ExtractJobRequirementsInput | null
  ): Promise<void> => {
    if (isNil(importedJob)) {
      return
    }

    const parsedResponse = await extractJobRequirements(importedJob)
    if (isNil(parsedResponse)) {
      const message = 'Error while extracting information from job listing'
      console.error(message)
      setFormError(message)
      return
    }

    const { location, salaryMin, salaryMax, workspace, title } = parsedResponse
    if (!isNil(location)) {
      setValue('locations', [location])
    }
    if (!isNil(workspace)) {
      setValue('workspace', workspace)
    }
    if (!isNil(salaryMin) && !isNil(salaryMax)) {
      setValue('salaryMin', salaryMin)
      setValue('salaryMax', salaryMax)
    }
    if (!isNil(title)) {
      setValue('title', title)
    }
    setFormError(null)
  }, [setValue])

  const getJobDescriptionFromImportedLink = useCallback(async (
    importedJob: ExtractJobRequirementsInput | null,
    { generateSummary = true }: { generateSummary?: boolean } = {}
  ): Promise<void> => {
    if (isNil(importedJob)) {
      return
    }

    const response = await extractIdealCandidate(importedJob)
    if (isNil(response)) {
      const message = 'Error while generating candidate description'
      console.error(message)
      setFormError(message)
      return
    }

    const { description } = response
    if (!isNil(description)) {
      setFormError(null)
      const output = generateSummary ? description : jobToImportFromLink?.importedJob?.content
      setValue('description', output)
      setValue('jobDescriptionRefinement', description)
      setValue('jobBoardDescription', jobToImportFromLink?.importedJob?.content)
    }
  }, [jobToImportFromLink?.importedJob?.content, setValue])

  // This is called when selecting a job from the dropdown listing
  // all imported jobs from all departments
  const handleCreateSummaryFromSelectedJob = async (url: string): Promise<void> => {
    const matchingJob = allImportedJobListings?.find((job) => job.url === url)
    if (matchingJob) {
      setValue('description', '')
      setFormView(FormView.GENERATING)
      setValue('title', matchingJob.title)
      const imported = {
        title: matchingJob.title,
        location: matchingJob.location,
        content: matchingJob.content
      }
      await Promise.all([
        getJobSummaryFromImportedLink(imported),
        getJobDescriptionFromImportedLink(imported)
      ])
      setFormView(FormView.START)
    }
  }

  // Called when a user pastes a url into the description / import field
  useEffect(() => {
    const prepareJobDataFromLink = async (): Promise<void> => {
      if (jobToImportFromLink.importedJob) {
        await Promise.all([
          getJobSummaryFromImportedLink(jobToImportFromLink.importedJob),
          getJobDescriptionFromImportedLink(
            jobToImportFromLink.importedJob,
            { generateSummary: false }
          )
        ])
        setFormView(FormView.START)
        setJobToImportFromLink((prev) => ({
          ...prev,
          isImporting: false
        }))
      }
    }
    void prepareJobDataFromLink()
  }, [getJobDescriptionFromImportedLink, getJobSummaryFromImportedLink, jobToImportFromLink.importedJob])

  const showComboboxWithImportedJobs = useMemo(
    () => (importedJobsByDepartment?.length ?? 0) >= 1 && !jobToImportFromLink.isImporting,
    [importedJobsByDepartment, jobToImportFromLink.isImporting]
  )

  const { additions: suggestedAdditions, refetch: refetchSuggestedAdditions } = useNewJobSuggestedAdditions({
    jobTitle: String(formData.title ?? ''),
    jobDescription: String(formData.description ?? '')
  })

  return (
    <S.Wrapper>
      <S.Inner>
        <Form onSubmit={submit(handleCreateJob)}>
          <Select
            name="departmentId"
            label="Hiring for"
            placeholder="Select an organization or department"
            defaultValue={formData.departmentId as string}
            items={departmentItems}
            $maxHeight={406}
            createItem={{
              value: 'new-company',
              title: 'New company',
              onClick: () => {
                openDialog(DialogId.CREATE_DEPARTMENT, {
                  setDepartmentId: (deptId: string): void => {
                    setValue('departmentId', deptId)
                  }
                })
              }
            }}
            createItemIsSticky={departmentItems?.length >= 8}
            register={register}
            $marginBottom={32}
          />
          <S.JobTitleHeader>
            <Caption size="SM" $color="fgSecondary">
              {formView === FormView.IMPORT_FROM_LINK ? 'Job listing link' : 'Position title'}
            </Caption>
            <When condition={formView === FormView.START}>
              <Button
                $variant="ghost"
                $colorTheme="tint"
                $height={24}
                $fontSize={12}
                leadingIcon="link"
                onClick={() => {
                  setFormView(FormView.IMPORT_FROM_LINK)
                }}
              >
                Import from link
              </Button>
            </When>
          </S.JobTitleHeader>
          <When condition={formView === FormView.IMPORT_FROM_LINK || jobToImportFromLink.isImporting}>
            <S.ImportJobInput $isLoading={false} $isDisabled={false} $isError={jobToImportFromLink?.isError}>
              <Icon name="link" size={14} color="fgSecondary" />
              <input
                ref={importLinkInputEl}
                disabled={jobToImportFromLink.isImporting}
                // placeholder="Link from a job posting listing"
                placeholder="https://"
                onFocus={() => {
                  setJobToImportFromLink(prev => ({
                    ...prev,
                    inputIsFocused: true
                  }))
                }}
                onBlur={() => {
                  setJobToImportFromLink(prev => ({
                    ...prev,
                    inputIsFocused: false
                  }))
                }}
              />
              <Flex $gap={4} $align="center" $justify="flex-end" $width="auto">
                <Button
                  $variant="flat"
                  $colorTheme="muted"
                  $height={28}
                  $fontSize={12}
                  onClick={() => {
                    setFormView(FormView.START)
                    setValue('title', '')
                    setJobToImportFromLink((prev) => ({
                      ...prev,
                      isError: false,
                      isImporting: false
                    }))
                  }}
                >
                  Cancel
                </Button>
                <Button
                  $variant="raised"
                  $colorTheme="tint"
                  $height={28}
                  $width={52}
                  $fontSize={12}
                  onClick={handleImportJobFromLink}
                  loading={jobToImportFromLink.isImporting}
                  disabled={jobToImportFromLink.isImporting}
                  $align="center"
                >
                  {jobToImportFromLink.isImporting ? <Spinner /> : 'Import'}
                </Button>
              </Flex>
            </S.ImportJobInput>
          </When>
          <When condition={(formView === FormView.START) && !showComboboxWithImportedJobs}>
            <Input
              name="title"
              placeholder="Eg: Software Engineer"
              label="Job title"
              hiddenLabel
              register={register}
              $marginBottom={(jobToImportFromLink?.isError || !isNil(formError)) ? 0 : 32}
            />
          </When>
          <When condition={(formView === FormView.START || formView === FormView.GENERATING) && showComboboxWithImportedJobs}>
            <Combobox
              name="title"
              acceptInputAsValue
              label="Job title"
              hiddenLabel
              placeholder="Eg: Software Engineer"
              defaultValue={formData?.title as string[]}
              options={importedJobsByDepartment ?? []}
              register={register}
              onOptionSelect={(value: string) => {
                void handleCreateSummaryFromSelectedJob(value)
              }}
              $marginBottom={(jobToImportFromLink?.isError || !isNil(formError)) ? 0 : 32}
              isLoading={isLoadingJobsForSelectedDepartment || formView === FormView.GENERATING}
            />
          </When>
          <When condition={jobToImportFromLink?.isError}>
            <FieldError>Url or job board not supported</FieldError>
            <Spacer $size={32} />
          </When>
          <When condition={!isNil(formError)}>
            <Spacer $size={12} />
            <Banner $variant='negative' icon="x-octagon">
              {formError}
            </Banner>
            <Spacer $size={32} />
          </When>
          <S.DescriptionFieldLabel $isDisabled={jobToImportFromLink.inputIsFocused}>
            <FieldLabel label="Candidate requirements or Job description" />
          </S.DescriptionFieldLabel>
          <S.DescriptionField $isDisabled={jobToImportFromLink.inputIsFocused}>
            <Textarea
              rows={12}
              label="Candidate requirements or Job description"
              hiddenLabel
              name="description"
              placeholder="E.g. location, years of experience, skills, etc."
              register={register}
              $marginBottom={0}
              autoGrow
              isDisabled={formView === FormView.GENERATING || jobToImportFromLink.inputIsFocused}
              onBlur={() => {
                refetchSuggestedAdditions()
              }}
            />
            <S.DescriptionFieldActions $hasGradient={formView === FormView.GENERATING}>
              <When condition={formView !== FormView.GENERATING}>
                <S.SuggestionsBox>
                  <Caption size="2XS" $color="fgTertiary" $transform="uppercase">
                    Examples
                  </Caption>
                  <S.Suggestions>
                    {(suggestedAdditions ?? DEFAULT_REFINEMENT_SUGGESTIONS).map((suggestion) => (
                      <S.Suggestion
                        key={suggestion.tag}
                        aria-label={`Add ${suggestion.tag} to search input for refinement`}
                        type="button"
                        onClick={() => {
                          const current = formData?.description as string
                          const updated = current ? `${current} ${suggestion.tag}` : suggestion.tag
                          setValue('description', updated)
                        }}
                      >
                        <span>{suggestion.tag}</span>
                      </S.Suggestion>
                    ))}
                  </S.Suggestions>
                </S.SuggestionsBox>
              </When>
              <When condition={formView === FormView.GENERATING}>
                <S.Generating>
                  <Flex $gap={8} $align="center">
                    <Icon name="sparkles-solid" size={12} />
                      <p>
                        {
                          jobToImportFromLink?.isImporting
                            ? (
                                <><span>Importing</span> <span>job</span> <span>description</span></>
                              )
                            : (
                                <><span>Generating</span> <span>job</span> <span>description</span></>
                              )
                        }
                      </p>
                  </Flex>
                  <Button
                    $variant="ghost"
                    $colorTheme="muted"
                    ariaLabel="Cancel generating job description"
                    onClick={() => {
                      setJobToImportFromLink((prev) => ({
                        ...prev,
                        isError: false,
                        isImporting: false
                      }))
                      setFormView(FormView.START)
                      setValue('title', '')
                    }}
                    $width={32}
                    $height={32}
                    leadingIcon="pause-circle"
                  />
                </S.Generating>
              </When>
            </S.DescriptionFieldActions>
          </S.DescriptionField>
          <Spacer $size={48} />
          <Flex $align="center" $gap={8}>
            <Button
              type="submit"
              $variant="fill"
              $colorTheme="tint"
              $height={40}
              loading={isSubmitting}
              disabled={!isFormValid || isSubmitting}
            >
              Create Job Position
            </Button>
            <Button
              type="button"
              $variant="outline"
              $colorTheme="muted"
              $height={40}
              onClick={() => {
                setFormView(FormView.START)
                setJobToImportFromLink({
                  isImporting: false,
                  isError: false,
                  inputIsFocused: false,
                  importedJob: null,
                  jobPostUrl: null
                })
                reset()
              }}
            >
              Reset
            </Button>
          </Flex>
        </Form>
      </S.Inner>
    </S.Wrapper>
  )
}
