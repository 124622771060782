import { useCallback, useMemo, useRef, useState } from 'react'
import { Button } from 'src/components/primitives/button'
import { Avatar } from '../../primitives/avatar'
import type { AvatarStyleProps } from '../../primitives/avatar'
import { Flex } from 'src/components/primitives/flex'
import { Caption, Paragraph, Span } from 'src/components/primitives/typography'
import type { Size } from 'src/components/primitives/typography'
import { When } from '../when'
import type {
  CandidateExpanded,
  CandidateEducation,
  CandidateExperienceGroupedByCompany,
  CandidateJobExpanded,
  CandidateJob,
  CandidateExperience,
  SourcingScores
} from 'src/libs/api/backend/candidate_jobs'
import type { Color, Spacing } from 'src/styles/theme/types'
import { Spacer } from 'src/components/primitives/spacer'
import { Icon, Icons } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import { groupBy, isEmpty, isNil } from 'lodash'
import { Timeline } from '../timeline'
import type { EntryProps as TimelineEntryProps, TimelineProps } from '../timeline'
import { getTimespan, getYearTimespan } from 'src/libs/time'
import * as S from './candidate-details.styled'
import { useKeyboardEvent } from 'src/hooks/use-keyboard-event'
import { IfElse } from '../if-else'
import { useToggleCandidateFavoriteStatus } from 'src/hooks/mutations/use-toggle-candidate-favorite-status'
import { CandidateJobStatusDropdown } from '../candidate-job-status'
import type { BrandIconName } from '../../primitives/brand-icon'
import type { CandidateActivity } from 'src/libs/api/backend/candidate_activities'
import { InboxTimeline } from '../inbox-timeline'
import { InboxCommentEditor } from '../inbox-comment-editor'
import { useCreateCandidateNote } from 'src/hooks/mutations/use-create-candidate-note'
import { Action } from '../inbox-actions/action'
import type { Org } from 'src/libs/api/backend/orgs'
import * as Portal from '@radix-ui/react-portal'
import { useContainerCoords } from 'src/hooks/use-container-coords'

const mapExperienceToTimeline = (
  groupedExperiences: CandidateExperienceGroupedByCompany
): TimelineEntryProps[] => {
  const experienceTagsByCompany: Record<string, Set<string>> = {}
  for (const [company, experiences] of Object.entries(groupedExperiences)) {
    if (isNil(experienceTagsByCompany[company])) {
      experienceTagsByCompany[company] = new Set()
    }

    for (const experience of experiences) {
      for (const tag of experience.experienceTags) {
        experienceTagsByCompany[company].add(tag)
      }
    }
  }

  return Object.entries(groupedExperiences).map(([company, experiences]) => {
    const firstExperience = experiences[0]

    const heading: TimelineEntryProps['heading'] = {
      title: firstExperience?.company ?? firstExperience?.companyInformation?.name ?? '',
      // description: firstExperience?.candidateSummary ?? firstExperience.companyInformation?.headline ?? firstExperience.companyInformation?.summary ?? '',
      description:
        firstExperience?.companyInformation?.gptSummary ??
        firstExperience?.companyInformation?.summary ??
        firstExperience?.companyInformation?.headline ??
        '',
      website: firstExperience?.companyInformation?.website,
      linkedin: firstExperience?.linkedin,
      logoUrl: firstExperience?.logoUrl,
      companyInformation: firstExperience?.companyInformation ?? undefined,
      overlappingEmployees: firstExperience?.overlappingClientCompanyEmployees ?? [],
      tags: Array.from(experienceTagsByCompany[company]).filter(tag => !isEmpty(tag))
    }

    const details: TimelineEntryProps['details'] = experiences.map((exp) => ({
      title: exp.title,
      timespan: exp.startDate ? getTimespan(exp.startDate, exp.endDate) : '',
      description: exp?.candidateSummary ?? ''
    }))

    return {
      heading,
      details,
      isCollapsible: true
    }
  })
}

const mapEducationToTimeline = (education: CandidateEducation): TimelineEntryProps => {
  const getEducationText = (): {
    heading: string
    details: string | null
    timespan: string | null
  } => {
    const preparedTimespan = education.startDate
      ? getYearTimespan(education.startDate, education.endDate)
      : ''

    if (!education.degree && !education.majors) {
      return {
        heading: education.school,
        details: null,
        timespan: preparedTimespan
      }
    }
    return {
      heading: education.school,
      details: [education.degree, education.majors].filter(Boolean).join(', '),
      timespan: preparedTimespan
    }
  }

  const heading: TimelineEntryProps['heading'] = {
    // title: [education.degree, education.majors].filter(Boolean).join(', ') ?? 'Academic studies or equivalent training',
    title: getEducationText().heading,
    description: '',
    website: education.website,
    linkedin: education.linkedin,
    logoUrl: education.logoUrl
  }

  const details: TimelineEntryProps['details'] = [
    {
      // title: education.school,
      title: getEducationText().details,
      timespan: getEducationText().timespan,
      description: null
    }
  ]

  return {
    heading,
    details,
    isCollapsible: false
  }
}

interface CandidateProfileInlineProps {
  org?: Org | undefined | null
  candidateJob?: CandidateJobExpanded
  heading?: string
  subheading?: string
  avatarSize?: Spacing
  includeSocialLinks?: boolean
}

export type SectionVariant = 'ghost' | 'plain'

interface SectionHeaderProps {
  isCollapsed?: boolean
  isCollapsible?: boolean
  onChange?: (value: boolean) => void
  children: React.ReactNode
}

export interface SectionStyleProps {
  $padding?: {
    left: Spacing
    right: Spacing
  }
}

interface SectionProps extends SectionHeaderProps, SectionStyleProps {
  $variant?: SectionVariant
  heading?: string | React.ReactNode
  headingTrailing?: React.ReactNode
  headingSpacingSize?: Spacing
  children: React.ReactNode
}

const SectionHeader = ({
  isCollapsible = false,
  isCollapsed = false,
  onChange,
  children
}: SectionHeaderProps): JSX.Element => {
  const [isCurrentlyCollapsed, setIsCurrentlyCollapsed] = useState(isCollapsed)
  const headerRef = useRef<HTMLDivElement>(null)

  useKeyboardEvent(
    ['Enter', ' '],
    () => {
      const newState = !isCurrentlyCollapsed
      if (onChange) {
        onChange(newState)
      }
      setIsCurrentlyCollapsed(newState)
    },
    { isEnabled: true, target: headerRef }
  )

  if (!isCollapsible) {
    return <S.SectionHeader $isCollapsible={false}>{children}</S.SectionHeader>
  }

  return (
    <S.SectionHeader $isCollapsible ref={headerRef} role="button" tabIndex={0}>
      <Flex $align="center" $gap={8}>
        {children}
        <Button
          nested
          ariaLabel="Collapse content"
          leadingIcon={isCurrentlyCollapsed ? 'chevron-right' : 'chevron-down'}
          $variant="ghost"
          $colorTheme="muted"
          $width={16}
          $height={16}
          $fontSize={12}
          onClick={() => {
            if (onChange) {
              const newState = !isCollapsed
              onChange(newState)
              setIsCurrentlyCollapsed(newState)
            }
          }}
        />
      </Flex>
    </S.SectionHeader>
  )
}

const Section = ({
  $variant = 'ghost',
  $padding = { left: 24, right: 24 },
  heading,
  headingTrailing,
  headingSpacingSize = 8,
  isCollapsible = false,
  children
}: SectionProps): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  return (
    <S.Section $variant={$variant} $padding={$padding}>
      <SectionHeader
        isCollapsed={isCollapsed}
        isCollapsible={isCollapsible}
        onChange={(value) => {
          setIsCollapsed(value)
        }}
      >
        <IfElse
          condition={typeof heading === 'string'}
          ifNode={
            <Caption size="2XS" $color="fgTertiary" $transform="uppercase">
              {heading}
            </Caption>
          }
          elseNode={<>{heading}</>}
        />
        {headingTrailing && <>{headingTrailing}</>}
      </SectionHeader>
      <Flex as="header" $gap={8} $align="center"></Flex>
      <Spacer $size={headingSpacingSize} />
      {!isCollapsed ? <>{children}</> : null}
    </S.Section>
  )
}

// facebook, twitter, github, stackoverflow, indeed, instagram, dribble

const candidateSocialLinkButtonProps = ({ platform, handle }: { platform: string, handle: string }): { leadingIcon: IconName | BrandIconName, href: string } | null => {
  switch (platform) {
    case 'linkedin':
      return { leadingIcon: 'linkedin' as const, href: new URL(handle, 'https://www.linkedin.com').toString() }
    case 'github':
      return { leadingIcon: 'github' as const, href: `https://github.com/${handle}` }
    case 'stackoverflow':
      return { leadingIcon: 'stackoverflow' as const, href: new URL(handle, 'https://stackoverflow.com').toString() }
    case 'twitter':
      return { leadingIcon: 'twitterX' as const, href: `https://twitter.com/${handle}` }
    case 'facebook':
      return { leadingIcon: 'facebook' as const, href: `https://facebook.com/${handle}` }
    case 'dribble':
      return { leadingIcon: 'dribbble' as const, href: `https://dribbble.com/${handle}` }
  }

  return null
}

const CandidateSocialLinks = ({ socials, linkedinUrl }: {
  linkedinUrl?: string | null
  socials: Array<{
    platform: string
    handle: string
  }>
}): JSX.Element => {
  return (
    // There's a 6px padding on the buttons, so 2px gap means 8px total gap
    <Flex $gap={2} $align="center" $width='fit-content'>
      {linkedinUrl && (
        <Button
          $variant="ghost"
          $colorTheme="muted"
          $width={20}
          $height={20}
          $fontSize={12}
          {...candidateSocialLinkButtonProps({ platform: 'linkedin', handle: linkedinUrl })}
        />
      )}

      {socials?.map(({ platform, handle }) => {
        if (platform === 'linkedin') {
          return null
        }

        const buttonProps = candidateSocialLinkButtonProps({ platform, handle })

        return buttonProps && (
          <Button
            key={`${platform}-${handle}`}
            $variant="ghost"
            $colorTheme="muted"
            $width={20}
            $height={20}
            $fontSize={12}
            {...buttonProps}
          />
        )
      })}
    </Flex>
  )
}

export const CandidateProfileInline = ({
  candidateJob,
  heading,
  subheading,
  avatarSize = 40,
  includeSocialLinks = false
}: CandidateProfileInlineProps): JSX.Element => {
  const { candidate, favorite } = candidateJob ?? {}
  const [isFavorited, setIsFavorited] = useState(favorite ?? false)
  const { toggleFavoriteStatus } = useToggleCandidateFavoriteStatus()
  const handleToggleFavoriteStatus = (): void => {
    if (isNil(candidateJob)) {
      return
    }
    const newStatus = !isFavorited
    setIsFavorited(newStatus)
    toggleFavoriteStatus({
      candidateJobId: candidateJob.id,
      newFavoriteStatus: newStatus
    })
  }
  return (
    <Flex $gap={12} $align="center">
      <Avatar
        $size={avatarSize}
        $shape={avatarSize > 40 ? 'rounded' : 'soft'}
        $type="photo"
        initials={candidate?.name}
        photoUrl={candidate?.profilePhotoUrl}
        fallbackAvatar="random"
      />
      <Flex $gap={12} $justify="space-between" $flex="1">
        <Flex $direction="column" $gap={4}>
          <Flex $align="center" $gap={8} $justify="space-between">
            <Flex $align="center" $gap={8}>
              <Caption size="MD" $whiteSpace="nowrap">
                {heading ?? candidate?.name}
              </Caption>
              <When condition={includeSocialLinks}>
                <CandidateSocialLinks linkedinUrl={candidate?.linkedin} socials={candidate?.socials ?? []} />
              </When>
            </Flex>
          </Flex>
          {(subheading ?? candidate?.location) && (
            <Paragraph size="XS" $whiteSpace="nowrap">
              {subheading ?? candidate?.location}
            </Paragraph>
          )}
        </Flex>
        <Flex $align="center" $gap={8} $width="auto">
          <Button
            $height={24}
            $width={24}
            leadingIcon={isFavorited ? 'star-fill' : 'star'}
            $variant="outline"
            $colorTheme={isFavorited ? 'warning' : 'muted'}
            $fontSize={12}
            ariaLabel="Toggle favorite status of candidate"
            onClick={() => {
              handleToggleFavoriteStatus()
            }}
          />
          <CandidateJobStatusDropdown $variant="flat" candidateJob={candidateJob} />
        </Flex>
      </Flex>
    </Flex>
  )
}

interface CandidateProfileProps {
  candidate: CandidateExpanded
  heading?: string
  headingSize?: Size
  subheading?: string | null
  horizontalGap?: Spacing
  verticalGap?: Spacing
  avatarSize?: Spacing
  avatarShape?: AvatarStyleProps['$shape']
  includeSocialLinks?: boolean
  socialLinksPosition?: 'top' | 'bottom'
  includeLatestExperienceTimeline?: boolean
  sourcingScores?: SourcingScores | null
}

export const CandidateProfile = ({
  candidate,
  heading,
  subheading,
  avatarSize = 40,
  headingSize = 'MD',
  horizontalGap,
  verticalGap,
  avatarShape,
  includeSocialLinks = false,
  socialLinksPosition = 'bottom',
  includeLatestExperienceTimeline = false,
  sourcingScores
}: CandidateProfileProps): JSX.Element => {
  if (isNil(subheading) && candidate.experiences?.length) {
    const latestExperience = candidate.experiences[0]
    subheading = [
      latestExperience.title,
      latestExperience.company
    ].filter(Boolean).join(' at ')
  }

  return (
    <S.Profile $gap={horizontalGap}>
      <Avatar
        $size={avatarSize}
        $shape={avatarShape ?? avatarSize > 40 ? 'rounded' : 'soft'}
        $type="photo"
        initials={candidate.name}
        photoUrl={candidate.profilePhotoUrl}
        fallbackAvatar="random"
      />
      <S.ProfileContent>
        <S.ProfileInner $verticalGap={verticalGap}>
          <Flex $align="center" $gap={8}>
            <Caption size={headingSize} $whiteSpace="nowrap">
              {heading ?? candidate.name}
            </Caption>
            <When condition={socialLinksPosition === 'top'}>
            <Flex $align="center" $gap={12} $width='fit-content'>
              <When condition={!isNil(sourcingScores?.criteria_matches)}>
                <CandidateMatchedCriteria sourcingScores={sourcingScores} />
              </When>
              <When condition={includeSocialLinks}>
                <CandidateSocialLinks linkedinUrl={candidate?.linkedin} socials={candidate.socials ?? []} />
              </When>
              </Flex>
            </When>
          </Flex>
          <When condition={!!subheading || !!candidate.location}>
            <Paragraph size="XS" $whiteSpace="nowrap" $ellipsis>
              {subheading}
              {includeLatestExperienceTimeline && candidate.experiences?.length && (
                <Span $color="fgTertiary" size='XS' $whiteSpace='nowrap'>
                  {subheading && ' · '}
                  {candidate.experiences[0].startDate &&
                    getTimespan(candidate.experiences[0].startDate, candidate.experiences[0].endDate)}
                </Span>
              )}
            </Paragraph>
          </When>
          <When condition={socialLinksPosition === 'bottom'}>
            <Flex $align="center" $gap={12} $width='fit-content'>
              <When condition={!isNil(sourcingScores?.criteria_matches)}>
                <CandidateMatchedCriteria sourcingScores={sourcingScores} />
              </When>
              <When condition={includeSocialLinks}>
                <CandidateSocialLinks linkedinUrl={candidate?.linkedin} socials={candidate.socials ?? []} />
              </When>
            </Flex>
          </When>
        </S.ProfileInner>
      </S.ProfileContent>
    </S.Profile>
  )
}

const CandidateMatchedCriteriaRow = ({ text, matched }: { text: string, matched: boolean }): JSX.Element => {
  return (
    <S.MatchedContent>
      <S.IconContainer>
        {matched ? <Icon name="check" color="positiveFg" /> : <Icon name="x" color="negativeFg" />}
      </S.IconContainer>
      <Caption size="XS" $fontWeight={400}>{text}</Caption>
    </S.MatchedContent>
  )
}

interface CandidateMatchedCriteriaProps {
  sourcingScores?: SourcingScores | null
}

export const CandidateMatchedCriteria = ({ sourcingScores }: CandidateMatchedCriteriaProps): JSX.Element => {
  const [hovered, setHovered] = useState(false)
  const { criteria_matches: criteriaMatches, criteria_matches_array: criteriaMatchesArray } = sourcingScores ?? {}
  const ref = useRef<HTMLDivElement>(null)
  const { coords } = useContainerCoords({ ref })
  const { total, matchedCount } = useMemo(() => {
    const total = Object.keys(criteriaMatches ?? {}).length
    const matchedCount = Object.values(criteriaMatches ?? {}).filter(Boolean).length
    return { total, matchedCount }
  }, [criteriaMatches])
  const { matchedCriterias, unmatchedCriterias } = useMemo(() => {
    const matchedCriterias = criteriaMatchesArray?.filter((criteria) => criteria.matched) ?? []
    const unmatchedCriterias = criteriaMatchesArray?.filter((criteria) => !criteria.matched) ?? []
    return { matchedCriterias, unmatchedCriterias }
  }, [criteriaMatchesArray])

  const matchCriteriaButtonStyle = useMemo((): { bg: Color, fg: Color } => {
    const matchedCount = matchedCriterias.length
    const totalCount = matchedCriterias.length + unmatchedCriterias.length
    const matchRatio = matchedCount / totalCount

    // "1 out of 1" is probably an edge case, but just to make sure
    if (matchRatio === 1 || matchRatio >= 2 / 3) {
      return { bg: 'positiveTranslucent10', fg: 'positiveFg' }
    }
    if (matchRatio >= 1 / 3) {
      return { bg: 'warningTranslucent10', fg: 'warningFg' }
    }
    return { bg: 'negativeTranslucent10', fg: 'negativeFg' }
  }, [matchedCriterias, unmatchedCriterias])

  const matchCriteraButton = useMemo(() => {
    // IT'S NOT A BUG TO HAVE THIS BUTTON TWICE
    return (
      <S.MatchedCriteria $bg={matchCriteriaButtonStyle.bg}>
        <Icon name={Icons.checkCheck} color={matchCriteriaButtonStyle.fg} size={12} />
        <Caption size="XS" $color={matchCriteriaButtonStyle.fg} >{matchedCount} of {total} criteria</Caption>
      </S.MatchedCriteria>
    )
  }, [matchedCount, total])

  return (
    <div
      onMouseEnter={() => {
        setHovered(true)
      }}
      onMouseLeave={() => {
        setHovered(false)
      }}
      ref={ref}
    >
      <Portal.Root
        container={ref.current}
        style={coords}
      >
        {matchCriteraButton}
        <S.MatchedCriteriaContainer
          $hovered={hovered}
        >
          {matchCriteraButton}
          <Flex $direction='column' $gap={20}>
            <When condition={matchedCriterias.length > 0}>
              <Flex $direction='column' $gap={8}>
                {matchedCriterias.map((criteria) => (
                  <CandidateMatchedCriteriaRow
                    key={criteria.id}
                    text={criteria.description}
                    matched={criteria.matched}
                  />
                ))}
              </Flex>
            </When>
            <When condition={unmatchedCriterias.length > 0}>
              <Flex $direction='column' $gap={8}>
                {unmatchedCriterias.map((criteria) => (
                  <CandidateMatchedCriteriaRow
                    key={criteria.id}
                    text={criteria.description}
                    matched={criteria.matched}
                  />
                ))}
              </Flex>
            </When>
          </Flex>
        </S.MatchedCriteriaContainer>
      </Portal.Root>
    </div>
  )
}

// interface AiInsightProps {
//   heading: string
//   subHeading: string
//   type: 'positive' | 'negative'
// }
//
// const getAIInsights = (candidateJob?: CandidateJobExpanded): AiInsightProps[] => {
//   const insights: AiInsightProps[] = []
//
//   if (!isNil(candidateJob?.yearsOfRelevantWork)) {
//     insights.push({
//       heading: `${candidateJob?.yearsOfRelevantWork} years`,
//       subHeading: 'of relevant work experience',
//       type: 'positive'
//     })
//   }
//
//   if (candidateJob?.hasRelevantEducation) {
//     insights.push({
//       heading: 'Degree',
//       subHeading: 'in relevant field for job',
//       type: 'positive'
//     })
//   }
//
//   return insights
// }
//
// const AiInsight = ({ heading, subHeading, type }: AiInsightProps): JSX.Element => {
//   const renderedHeading = heading === '1 years' ? '1 year' : heading
//
//   return (
//     <>
//       <Flex $gap={8} $align="center">
//         <Badge $shape="dot" $variant={type} />
//         <Caption size="XS">{renderedHeading} </Caption>
//         <Caption size="XS" $color="fgSecondary">
//           {subHeading}
//         </Caption>
//       </Flex>
//       <Spacer $size={6} />
//     </>
//   )
// }
//
// interface CandidateAiInsightsProps {
//   candidateJob?: CandidateJobExpanded
// }
//
// export const CandidateAiInsights = ({ candidateJob }: CandidateAiInsightsProps): JSX.Element => {
//   return (
//     <Section
//       $variant="plain"
//       heading={
//         <Flex $align="center">
//           <Icon name="sparkles-solid" color="positiveFg" />
//           <Caption size="XS" $color="positiveFg">
//             &nbsp;AI Insights
//             {/* {!isNil(candidateJob?.compatibility) ? ` • ${(candidateJob?.compatibility * 5).toFixed(1)} out of 5` : ''} */}
//           </Caption>
//         </Flex>
//       }
//     >
//       <S.Insights>
//         {getAIInsights(candidateJob).map((insight: AiInsightProps) => (
//           <AiInsight key={insight.heading} {...insight} />
//         ))}
//       </S.Insights>
//     </Section>
//   )
// }

interface CandidateEducationProps extends Pick<TimelineProps, '$padding'> {
  isCollapsible?: boolean
  educations: CandidateExpanded['educations']
}

export const CandidateEducationTimeline = ({
  isCollapsible = false,
  educations,
  $padding
}: CandidateEducationProps): JSX.Element => {
  if (!educations?.length) {
    return <Spacer $size={2} />
  }

  return (
    <Section
      isCollapsible={isCollapsible}
      heading="Education"
      headingSpacingSize={0}
      $padding={$padding}
    >
      <Spacer $size={8} />
      <When condition={Boolean(educations?.length)}>
        <Timeline
          isCollapsible={isCollapsible}
          max={1}
          avatarFallbackIcon="school"
          entries={educations
            .map((edu: CandidateEducation) => mapEducationToTimeline(edu))
            .slice(0, 1)}
          $padding={$padding}
        />
      </When>
    </Section>
  )
}

interface CandidateExperienceTimelineProps extends Pick<TimelineProps, '$padding'> {
  experiences: CandidateExpanded['experiences']
}

export const CandidateExperienceTimeline = ({
  experiences,
  $padding
}: CandidateExperienceTimelineProps): JSX.Element => {
  const experiencesGroupedByCompany: CandidateExperienceGroupedByCompany = groupBy(
    experiences,
    (experience) => experience.grouping
  )

  return (
    <Section heading="Experience" $padding={$padding}>
      <When condition={experiences?.length >= 1 && !!experiencesGroupedByCompany}>
        <Timeline
          isCollapsible
          max={4}
          entries={mapExperienceToTimeline(experiencesGroupedByCompany)}
          $padding={$padding}
        />
      </When>
      <When condition={!experiences}>
        <S.Empty>
          <Icon name="bird" size={24} color="fgTertiary" />
          <p>No experiences found or provided</p>
        </S.Empty>
      </When>
    </Section>
  )
}

export interface CandidateSummaryStyleProps {
  $padding?: {
    top?: Spacing
    right?: Spacing
    bottom?: Spacing
    left?: Spacing
  }
}

interface CandidateSummaryProps extends CandidateSummaryStyleProps {
  candidateJob?: CandidateJob
  candidate?: CandidateExpanded
}

export const CandidateSummary = ({
  candidateJob,
  $padding = { top: 16, right: 24, bottom: 0, left: 24 }
}: CandidateSummaryProps): JSX.Element => {
  return (
    <S.CandidateSummary $padding={$padding}>
      {candidateJob?.gptProfileSummary && (
        <Flex $gap={8} $direction="column">
          <S.Insights>
            <Paragraph size="XS" $whiteSpace="pre-wrap" $color="fgPrimary" $lineHeight={1.5}>
              <span dangerouslySetInnerHTML={{ __html: candidateJob.gptProfileSummary }} />
            </Paragraph>
          </S.Insights>
        </Flex>
      )}
    </S.CandidateSummary>
  )
}

interface CompactCandidateDetailsProps {
  logo?: string | null
  title: string | null
  institution: string
  location?: string
  timespan: string
}

const CompactCandidateDetails = ({
  logo,
  title,
  institution,
  location,
  timespan
}: CompactCandidateDetailsProps): JSX.Element => {
  return (
    <Flex $gap={8} $align="center">
      <Avatar $size={16} $shape="soft" $type="photo" photoUrl={logo} fallbackAvatar="random" />
      <Flex $direction="row" $flex="1" $gap={4}>
        <Caption size="XS" $whiteSpace="nowrap" $fontWeight={400}>
          {title}
        </Caption>
        <When condition={!!institution}>
          <Caption size="XS" $whiteSpace="nowrap" $fontWeight={400}>
            at {institution}
          </Caption>
        </When>
        <When condition={!!location}>
          <Paragraph size="XS" $whiteSpace="nowrap">
            · {location}
          </Paragraph>
        </When>
        <When condition={!!timespan}>
          <Paragraph size="XS" $whiteSpace="nowrap">
            · {timespan}
          </Paragraph>
        </When>
      </Flex>
    </Flex>
  )
}

interface CandidateCompactProfile {
  candidate: CandidateExpanded
  avatarSize: Spacing
}

export const CompactCandidateProfile = ({
  candidate,
  avatarSize
}: CandidateCompactProfile): JSX.Element => {
  const { experiences, educations } = candidate
  const latestExperience = experiences?.[0]
  const latestEducation = educations?.[0]
  return (
    <S.Profile>
      <Avatar
        $size={avatarSize}
        $shape="rounded"
        $type="photo"
        initials={candidate.name}
        photoUrl={candidate.profilePhotoUrl}
        fallbackAvatar="random"
      />
      <Flex $direction="column" $gap={8} $height="full">
        <Flex $direction="row" $gap={6} $align="center">
          <Flex $align="center" $gap={8} $width="fit-content">
            <Caption size="MD" $whiteSpace="nowrap">
              {candidate.name}
            </Caption>
          </Flex>
          <Flex $gap={8} $align="center" $width="fit-content">
            {candidate.linkedin && (
              <Button
                $variant="ghost"
                $colorTheme="muted"
                $width={20}
                $height={20}
                $fontSize={12}
                leadingIcon="linkedin"
                href={`https://www.linkedin.com${candidate.linkedin}`}
              />
            )}
          </Flex>
        </Flex>
        {latestExperience && (
          <CompactCandidateDetails
            logo={latestExperience.logoUrl}
            title={latestExperience.title}
            institution={latestExperience.company}
            timespan={
              latestExperience.startDate
                ? getTimespan(latestExperience.startDate, latestExperience.endDate)
                : ''
            }
          />
        )}
        {latestEducation && (
          <CompactCandidateDetails
            logo={latestEducation.logoUrl}
            title={latestEducation.degree}
            institution={latestEducation.school}
            timespan={
              latestEducation.startDate
                ? getYearTimespan(latestEducation.startDate, latestEducation.endDate)
                : ''
            }
          />
        )}
      </Flex>
    </S.Profile>
  )
}

interface CandidateQuickOverviewProps {
  candidateJob?: CandidateJobExpanded
}

interface QuickOverviewCompanyBadgeProps {
  experience: CandidateExperience
}

const QuickOverviewCompanyBadge = ({ experience }: QuickOverviewCompanyBadgeProps): JSX.Element => {
  return (
    <S.QuickOverviewCompanyBadge>
      <Avatar
        $size={16}
        $shape="soft"
        $type="logo"
        $innerPadding={0}
        $border={false}
        company={{ name: experience?.company, url: experience?.companyInformation?.website, logoUrl: experience.logoUrl }}
        initials={experience?.company}
        fallbackAvatar="random"
      />
      <Span size="XS" $whiteSpace="pre-wrap" $color="fgPrimary">
        {experience?.company}
      </Span>
    </S.QuickOverviewCompanyBadge>
  )
}

export const CandidateQuickOverview = ({ candidateJob }: CandidateQuickOverviewProps): JSX.Element => {
  const latestEducation = candidateJob?.candidate?.educations?.[0]

  const getUniqueExperiences = (experiences: CandidateExperience[]): CandidateExperience[] => {
    const uniqueCompanies = new Set<string>()
    const uniqueExperiences: CandidateExperience[] = []

    experiences.forEach(exp => {
      if (!uniqueCompanies.has(exp.company)) {
        uniqueCompanies.add(exp.company)
        uniqueExperiences.push(exp)
      }
    })

    return uniqueExperiences
  }

  const uniqueExperiences = getUniqueExperiences(candidateJob?.candidate?.experiences ?? [])
  const latestExperience = uniqueExperiences?.[0]
  const additionalExperiences = uniqueExperiences?.slice(1, 4)
  const remainingExperiencesCount = uniqueExperiences.length - additionalExperiences.length - 1

  if (!candidateJob) {
    return <></>
  }

  return (
    <S.CandidateQuickOverview>
      <S.QuickOverviewRow>
        <S.QuickOverviewRowIcon>
          <Icon name="pin-normal" color="fgSecondary" />
        </S.QuickOverviewRowIcon>
        <S.QuickOverviewDetails>
          <S.Insights>
            <Paragraph size="XS" $whiteSpace="pre-wrap" $lineHeight={1.5} $color="fgPrimary">
              <span dangerouslySetInnerHTML={{ __html: candidateJob.gptProfileSummary ?? '' }} />
            </Paragraph>
          </S.Insights>
        </S.QuickOverviewDetails>
      </S.QuickOverviewRow>
      <S.QuickOverviewRow>
        <S.QuickOverviewRowIcon>
          <Avatar
            $size={16}
            $shape="soft"
            $type="logo"
            $innerPadding={0}
            $border={false}
            company={{ name: latestExperience?.company, url: latestExperience?.companyInformation?.website, logoUrl: latestExperience?.logoUrl }}
            initials={latestExperience?.company}
            fallbackAvatar="random"
          />
        </S.QuickOverviewRowIcon>
        <S.QuickOverviewDetails>
          <Flex $direction="column" $gap={6}>
            <Flex $align="center">
              <Paragraph size="XS" $whiteSpace="pre-wrap" $color="fgPrimary">
                {latestExperience?.title} at {latestExperience?.company}{' '}
              </Paragraph>
              {latestExperience?.startDate && (
                <Paragraph size="XS" $whiteSpace="nowrap">
                  · {getTimespan(latestExperience?.startDate, latestExperience?.endDate)}
                </Paragraph>
              )}
            </Flex>
            {additionalExperiences?.length
              ? (
                <Flex $align="center">
                  <Paragraph size="XS" $whiteSpace="pre-wrap" $color="fgPrimary">
                    Worked at{' '}
                  </Paragraph>
                  {additionalExperiences?.map(exp => <QuickOverviewCompanyBadge key={exp.company} experience={exp} />)}
                  {remainingExperiencesCount >= 1 ? <Span size="XS" $whiteSpace="pre-wrap" $color="fgPrimary">{' '} and {remainingExperiencesCount} more</Span> : null}
                </Flex>
                )
              : (
                <></>
                )
            }
          </Flex>
        </S.QuickOverviewDetails>
      </S.QuickOverviewRow>
      <When condition={Boolean(latestEducation?.degree && latestEducation?.school)}>
        <S.QuickOverviewRow>
          <S.QuickOverviewRowIcon>
            <Avatar
              $size={16}
              $shape="soft"
              $type="logo"
              $innerPadding={0}
              $border={false}
              company={{ name: latestEducation?.school, url: latestEducation?.website, logoUrl: latestEducation?.logoUrl }}
              initials={latestEducation?.school}
              fallbackAvatar="random"
            />
          </S.QuickOverviewRowIcon>
          <S.QuickOverviewDetails>
            <Flex $align="center">
              <Paragraph size="XS" $whiteSpace="pre-wrap" $color="fgPrimary">
                {latestEducation?.degree} at {latestEducation?.school}{' '}
              </Paragraph>
            </Flex>
          </S.QuickOverviewDetails>
        </S.QuickOverviewRow>
      </When>
    </S.CandidateQuickOverview>
  )
}

interface CandidateTimelineProps {
  candidateActivites: CandidateActivity[]
  candidate: CandidateExpanded
  $padding: SectionStyleProps['$padding']
  candidateJob: CandidateJobExpanded
}

export const CandidateTimeline = ({
  candidateActivites,
  candidate,
  $padding,
  candidateJob
}: CandidateTimelineProps): JSX.Element => {
  const [commentContent, setCommentContent] = useState('')
  const { createCandidateNote } = useCreateCandidateNote()
  const handleAddComment = useCallback((): void => {
    if (isNil(commentContent)) {
      return
    }
    createCandidateNote({
      candidateId: candidate.id,
      body: commentContent,
      onSuccess: () => {
        setCommentContent('')
      }
    })
  }, [candidate.id, candidateJob, commentContent, createCandidateNote])
  return (
    <Section heading="Timeline" $padding={$padding} headingSpacingSize={20}>
      <Flex $direction='column'>
        <InboxTimeline
          $gap={24}
          candidate={candidate}
          activities={candidateActivites}
          newActivityOpen={true}
        />
        <Action actionType="COMMENT">
          <Flex $align="center" $justify="space-between">
            <Paragraph size="XS" $color="fgSecondary">
              New comment
            </Paragraph>
          </Flex>
          <InboxCommentEditor
            onDataChanged={(data) => {
              setCommentContent(data)
            }}
            onAddComment={handleAddComment}
            forceEditorFocus={false}
          />
        </Action>
      </Flex>
    </Section>
  )
}
