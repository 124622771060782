import styled from 'styled-components'

export const InSequenceStat = styled.div`
  display: flex;
  padding: ${({ theme }) => `${theme.spacing[12]} ${theme.spacing[18]}`};
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing[4]};
  flex: 1 0 0;
  border-radius: ${({ theme }) => theme.spacing[4]};
  background: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
`
