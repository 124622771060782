import { CandidatesPageHeader } from '../candidates-page-header'
import type { CandidatesPageHeaderProps } from '../candidates-page-header'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'
import { CANDIDATES_PAGES_MAX_WIDTH } from 'src/styles/constants'
import { useMemo } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import type { ViewMode } from 'src/constants'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { Button } from 'src/components/primitives'
import { Icons } from 'src/components/primitives/icon'
import RouteBuilder from 'src/libs/route-builder'
import { useDeleteJobSearchRefinement } from 'src/hooks/mutations/use-delete-job-search-refinement'

interface SourcingPageHeaderProps extends Pick<CandidatesPageHeaderProps, 'isEditable' | 'onEdit' | 'headingLoading'> {
  setViewMode: Dispatch<SetStateAction<ViewMode>>
  currViewMode: ViewMode
  title: string
  showViewMode?: boolean
  showActions?: boolean
  jobSearchRefinementId?: string | null
  jobId?: string
  candidateJobIds: string[]
  isSequenceEmpty: boolean
}

export const SourcingPageHeader = ({
  headingLoading,
  setViewMode,
  currViewMode,
  title,
  showActions = true,
  showViewMode = true,
  jobId,
  jobSearchRefinementId,
  ...headerProps
}: SourcingPageHeaderProps): JSX.Element => {
  const { openDialog, openAlert } = useDialog()
  const { deleteSearchRefinement } = useDeleteJobSearchRefinement()

  const headerActions: CandidatesPageHeaderProps['actions'] = [
    {
      children: 'Add Candidate',
      icon: 'plus',
      // $variant: 'outline',
      $colorTheme: 'tint',
      onClick: () => {
        openDialog(DialogId.ADD_CANDIDATE)
      }
    }
  ]

  const contextItems = useMemo(() => {
    const items: MenuItemProps[] = []
    items.push({
      title: 'Position Details',
      icon: Icons.settings2,
      href: RouteBuilder.build('SETTINGS_JOB_DETAILS', { jobId })
    })
    if (jobSearchRefinementId) {
      items.push({
        title: 'Delete Search',
        icon: Icons.trash,
        variant: 'negative',
        onSelect: () => {
          if (jobSearchRefinementId) {
            openAlert({
              message: 'Are you sure you want to delete this search?',
              description: 'All candidates within this search will be lost.',
              cancelText: 'Cancel',
              confirmText: 'Delete',
              onConfirm: () => {
                deleteSearchRefinement({ jobSearchRefinementId })
              }
            })
          }
        }
      })
    }
    return items
  }, [deleteSearchRefinement, jobId, jobSearchRefinementId, openAlert])

  return (
    <div style={{ maxWidth: CANDIDATES_PAGES_MAX_WIDTH, width: '100%' }}>
      <CandidatesPageHeader
        heading={title}
        headingLoading={headingLoading}
        actions={showActions ? headerActions : []}
        setViewMode={showViewMode ? setViewMode : undefined}
        currViewMode={showViewMode ? currViewMode : undefined}
        context={
          <Dropdown
            trigger={
              <Button
                nested
                ariaLabel="Options"
                $variant="ghost"
                $colorTheme="muted"
                $width={24}
                $height={24}
                $fontSize={12}
                leadingIcon="more-vertical"
              />
            }
            items={contextItems}
            menuPosition="end"
            size="small"
            $minWidth='150px'
            $menuWidth='auto'
          />
        }
        {...headerProps}
      />
    </div>
  )
}
