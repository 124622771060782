import { EmptyState } from 'src/components/blocks/empty-state'
import { Button, Flex } from 'src/components/primitives'
import { Icons } from 'src/components/primitives/icon'
import { useCandidateSequenceStepMessageReviews } from 'src/hooks/queries/use-candidate-sequence-step-message-review'
import RouteBuilder from 'src/libs/route-builder'
import * as S from './ready-to-send-page.styled'
import { ReadyToSend } from 'src/components/blocks/ready-to-send'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import { isNil, keyBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useToggleCandidateFavoriteStatus } from 'src/hooks/mutations/use-toggle-candidate-favorite-status'
import { useSendCandidateSequenceStepMessage } from 'src/hooks/mutations/use-send-candidate-sequence-step-message'
import { When } from 'src/components/blocks/when'
import { Banner } from 'src/components/blocks/banner'
import { useUpsertJobSequence } from 'src/hooks/mutations/use-upsert-job-sequence'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { useSession } from 'src/hooks/use-session'
import { getEmailAccountAuthUrl } from 'src/libs/auth-urls'
import type { EmailAccount } from 'src/libs/api/backend/users'
import { SEO } from 'src/components/primitives/seo'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'
import { invalidateEmailAccounts } from 'src/hooks/invalidate-email-accounts'
import { Paragraph } from 'src/components/primitives/typography'
import { useRefreshManualEmailCopy } from 'src/hooks/mutations/use-refresh-manual-email-copy'
import type { CandidateSequenceStepMessageReview } from 'src/libs/api/backend/candidate_sequence_step_message_review'

interface ReadyToSendBannerParams {
  jobId: string
  emailSequenceActive?: boolean
  dirtyCandidateSequenceStepMessageReviews: CandidateSequenceStepMessageReview[]
}

const ReadyToSendBanner = ({ jobId, emailSequenceActive, dirtyCandidateSequenceStepMessageReviews }: ReadyToSendBannerParams): JSX.Element => {
  const { upsertJobSequence } = useUpsertJobSequence()
  const { refreshManualEmailCopy } = useRefreshManualEmailCopy()
  const { openAlert } = useDialog()

  const dirtyCandidateSequenceStepMessageReviewIds = useMemo(() => {
    return dirtyCandidateSequenceStepMessageReviews.map(cssmr => cssmr.id)
  }, [dirtyCandidateSequenceStepMessageReviews])

  return (
    <Flex $gap={18} $direction='column' $width='100%'>
      <Flex $align='center' $justify='space-between'>
        <S.ReadyToSendHeader as="h1" size="MD" $whiteSpace="nowrap">
          Outreach &middot; Ready to send
        </S.ReadyToSendHeader>
        <Flex $gap={8} $justify="flex-end" $width="auto" $align="center">
          <Button
            leadingIcon={Icons.settings2}
            href={RouteBuilder.build('SETTINGS_JOB_EMAIL_SEQUENCE', { jobId })}
            $variant='ghost'
            $colorTheme='muted'
            $fontSize={12}
            $height={24}
          >
            Edit Outreach
          </Button>
        </Flex>
      </Flex>
      <When condition={!emailSequenceActive && dirtyCandidateSequenceStepMessageReviews.length === 0}>
        <Flex $direction='column'>
          <Banner
            icon="pause-circle"
            $variant="muted"
            actions={
              <Button
                $variant="fill"
                $colorTheme="tint"
                $height={24}
                $fontSize={12}
                leadingIcon="play-circle"
                onClick={() => {
                  const newState = !emailSequenceActive
                  upsertJobSequence({
                    jobId,
                    active: newState,
                    toastMessage: newState ? 'Enabled sending outreach emails' : 'Paused sending outreach emails'
                  })
                }}
              >
                Enable outreach
              </Button>
            }
          >
            Outreach is paused. Enable it to start sending emails.
          </Banner>
        </Flex>
      </When>
      <When condition={dirtyCandidateSequenceStepMessageReviews.length >= 1}>
        <Banner $variant='warning' >
          <Flex $direction='row' $align='center' $justify='space-between'>
            <Paragraph> The sequence's template has changed since these emails were generated. Rebuilding will use the current sequence template. </Paragraph> <Button
              leadingIcon={Icons.refreshCw}
              $height={24}
              $fontSize={12}
              onClick={() => {
                openAlert({
                  message: 'Are you sure you want to refresh?',
                  description: 'Any unsent edits in the queue that will be discarded',
                  onConfirm: () => {
                    refreshManualEmailCopy({ jobId, dirtyCandidateSequenceStepMessageReviewIds })
                  }
                })
              }}
            >
              Rebuild
            </Button>
          </Flex>
        </Banner>
      </When>
    </Flex>
  )
}

interface ReadyToSendPageProps {
  jobId: string
}

const ReadyToSendPage = ({ jobId }: ReadyToSendPageProps): JSX.Element => {
  const { data: candidateSequenceStepMessageReviews, isPending } = useCandidateSequenceStepMessageReviews({ jobId })
  const { data: users, refetch: refetchOrgUsers } = useOrgUsersQuery()
  const { data: emailSequence } = useJobSequenceQuery()
  const { user } = useSession()
  const { toggleFavoriteStatus } = useToggleCandidateFavoriteStatus()
  const { sendCandidateSequenceStepMessage } = useSendCandidateSequenceStepMessage()
  const { openDialog } = useDialog()

  const dirtyCandidateSequenceStepMessageReviews = useMemo(() => {
    if (isPending || isNil(candidateSequenceStepMessageReviews)) {
      return []
    }

    return candidateSequenceStepMessageReviews.filter((cssmr) => cssmr.dirty)
  }, [isPending, candidateSequenceStepMessageReviews])

  const handleToggleFavoriteStatus = useCallback((candidateJobId: string, newStatus: boolean): void => {
    toggleFavoriteStatus({
      candidateJobId,
      newFavoriteStatus: newStatus
    })
  }, [toggleFavoriteStatus])

  const handleSendEmail = useCallback((candidateSequenceStepMessageReviewId: string, subject: string, body: string): void => {
    sendCandidateSequenceStepMessage({
      jobId,
      candidateSequenceStepMessageReviewId,
      subject,
      body
    })
  }, [jobId, sendCandidateSequenceStepMessage])

  const reconnect = useCallback((selectedEmailAccount: EmailAccount | undefined): void => {
    if (!isNil(selectedEmailAccount)) {
      const redirectUrl = `${window.location.origin}/login/redirect/close`
      const authUrl = getEmailAccountAuthUrl(selectedEmailAccount, redirectUrl)
      const loginWindow = window.open(authUrl, '_blank', 'popup=1,height=600,width=600')
      const timer = setInterval(() => {
        if (loginWindow?.closed) {
          void invalidateEmailAccounts()
          void refetchOrgUsers()
          clearInterval(timer)
        }
      }, 500)
    }
  }, [refetchOrgUsers])

  const usersByUserId = useMemo(() => {
    if (isNil(users)) {
      return {}
    }
    return keyBy(users, 'id')
  }, [users])

  const openCandidateDialog = useCallback((candidateJobId: string): void => {
    openDialog(DialogId.CANDIDATE_DETAILS, candidateJobId)
  }, [openDialog])

  if (isPending) {
    return (
      <S.ReadyToSendPageInner>
        <SEO title='Ready To Send' />
      </S.ReadyToSendPageInner>
    )
  }

  if (!candidateSequenceStepMessageReviews || candidateSequenceStepMessageReviews.length === 0) {
    return (
      <S.ReadyToSendPageInner>
        <SEO title='Ready To Send' />
        <S.ReadyToSendWrapper>
          <S.ReadyToSendList>
            <ReadyToSendBanner
              jobId={jobId}
              emailSequenceActive={emailSequence?.active}
              dirtyCandidateSequenceStepMessageReviews={dirtyCandidateSequenceStepMessageReviews}
            />
            <EmptyState
              heading="No one at this step"
              description="Looks like you don’t have any prospects to reach out yet, start sourcing candidates."
              svg="profileCard"
              $padding={{
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
              }}
              actions={[
                {
                  href: RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId }),
                  children: 'Go to sourcing'
                }
              ]}
            />
          </S.ReadyToSendList>
        </S.ReadyToSendWrapper>
      </S.ReadyToSendPageInner>
    )
  }

  return (
    <S.ReadyToSendPageInner>
      <SEO title='Ready To Send' />
      <S.ReadyToSendWrapper>
        <S.ReadyToSendList>
          <ReadyToSendBanner
            jobId={jobId}
            emailSequenceActive={emailSequence?.active}
            dirtyCandidateSequenceStepMessageReviews={dirtyCandidateSequenceStepMessageReviews}
          />
          {candidateSequenceStepMessageReviews.map((candidateSequenceStepMessageReview) => (
            <ReadyToSend
              key={candidateSequenceStepMessageReview.id}
              usersByUserId={usersByUserId}
              handleToggleFavoriteStatus={handleToggleFavoriteStatus}
              handleSendEmail={handleSendEmail}
              candidateSequenceStepMessageReview={candidateSequenceStepMessageReview}
              sendDisabled={!emailSequence?.active}
              currentUserId={user?.id}
              reconnect={reconnect}
              openCandidateDialog={openCandidateDialog}
            />
          ))}
          <div>&nbsp;</div>
        </S.ReadyToSendList>
      </S.ReadyToSendWrapper>
    </S.ReadyToSendPageInner>
  )
}

export default ReadyToSendPage
