import { z } from 'zod'
import Api, { ApiError } from '..'
import { userParser } from './users'
import { orgParser } from './orgs'
import { identifyTrackedUser } from 'src/libs/track'

export const emailAccountAccessTokenParser = z.object({
  emailAccountId: z.string().uuid(),
  type: z.enum(['google', 'microsoft']),
  email: z.string(),
  hasAccessToken: z.boolean(),
  usedInJobIds: z.array(z.string().uuid()),
  teamSendAsPermission: z.boolean().nullish(),
  isPrimary: z.boolean()
})

export type EmailAccount = z.infer<typeof emailAccountAccessTokenParser>

export enum FeatureFlags {
  AI_SALARY_ESTIMATION = 'AI_SALARY_ESTIMATION',
  SALARY_PICKER = 'SALARY_PICKER',
  COMPANY_PREFERENCES = 'COMPANY_PREFERENCES',
  SKILL_TAGS = 'SKILL_TAGS',
  CANDIDATE_TAGS = 'CANDIDATE_TAGS',
  MANUAL_OUTREACH = 'MANUAL_OUTREACH',
  SEQUENCE_PREFERENCES = 'SEQUENCE_PREFERENCES',
  SUGGESTED_RESPONSES = 'SUGGESTED_RESPONSES',
  AI_AUTOMATION = 'AI_AUTOMATION',
  CUSTOM_OUTREACH = 'CUSTOM_OUTREACH',
  CALENDAR = 'CALENDAR',
  ATS_INTEGRATION = 'ATS_INTEGRATION',
  LARGER_CANDIDATE_RATING_MODEL = 'LARGER_CANDIDATE_RATING_MODEL',
  EMAIL_SEARCH_PREFERENCE = 'EMAIL_SEARCH_PREFERENCE'
}

export enum SUBSCRIPTION_ISSUE {
  SUBSCRIPTION_EXPIRED = 'subscription_expired',
  PAYMENT_FAILED = 'payment_failed',
  EXTENDED_TRIAL_EXPIRED = 'extended_trial_expired'
}

const sessionParser = z.object({
  user: userParser.extend({
    profilePhoto: z.string().nullish(),
    timezone: z.string().nullish()
  }),
  org: orgParser.nullable(),
  onboarding: z.enum(['ORG', 'ORG_JOIN', 'JOB', 'SEQUENCE', 'COMPLETE']),
  emailAccountAccessTokens: z.array(emailAccountAccessTokenParser),
  intercomUserHash: z.string().nullish(),
  logoUrl: z.string().nullish(),
  featureFlags: z.array(z.string()).nullish().transform(flags => {
    if (!flags) return []
    return flags.filter(flag => Object.values(FeatureFlags).includes(flag as FeatureFlags)) as FeatureFlags[]
  }),
  subscribed: z.boolean().nullish(),
  trialActive: z.boolean().nullish(),
  impersonating: z.boolean().nullish(),
  aiSourcedJobsLimit: z.number().default(0),
  subscriptionIssue: z.nativeEnum(SUBSCRIPTION_ISSUE).nullish()
})

export type Session = z.infer<typeof sessionParser>

export class SessionNotLoggedInError extends Error {
  constructor () {
    super()
    this.name = 'SessionNotLoggedInError'
  }
}

export async function fetchSession (): Promise<Session> {
  try {
    const response = await Api.get('/session')
    if (response?.data) {
      const session = sessionParser.parse(response.data)
      if (!session.impersonating) {
        identifyTrackedUser(session.user.id, {
          name: session.user.name,
          emailAccount: session.emailAccountAccessTokens.find(e => e.isPrimary) ?? session.emailAccountAccessTokens[0],
          org: session.org
        })
      }
    }
    return sessionParser.parse(response.data)
  } catch (e) {
    if (e instanceof ApiError) {
      if (e.status === 401) {
        throw new SessionNotLoggedInError()
      }
    }

    throw e
  }
}

export type OnboardingStatus = Session['onboarding']
