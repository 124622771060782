import * as Dialog from 'src/components/primitives/dialog'
import { useDialog } from 'src/hooks/use-dialog'
import * as S from './new-job.styled'
import { DialogId } from 'src/contexts/dialogs'
import { CreateNewJobForm } from 'src/components/blocks/create-new-job-form'
import { useQueryParams } from 'src/hooks/use-query-params'

export const CreateNewJobDialog = (): JSX.Element => {
  const { isDialogOpen, controlDialog } = useDialog()
  const { resetParam } = useQueryParams()

  return (
    <Dialog.Root
      id={DialogId.CREATE_NEW_JOB}
      isOpen={isDialogOpen(DialogId.CREATE_NEW_JOB)}
      modal
      onOpenChange={(value) => {
        if (!value) {
          resetParam('createNewJob')
        }
        controlDialog(DialogId.CREATE_NEW_JOB, value)
      }}
      $width="almostFull"
      $height="almostFull"
      $innerPadding={{ top: 0, right: 0, bottom: 0, left: 0 }}
    >
      <Dialog.Portal>
        <Dialog.Header
          title="New Job Position"
          onClose={() => {
            resetParam('createNewJob')
            controlDialog(DialogId.CREATE_NEW_JOB, false)
          }}
        />
        <Dialog.Content>
          <S.NewJobWrapper>
            <S.ImageWrapper>
              <S.ImageWrapperInner>
                <img src="/onboarding/company-profile.jpg" />
              </S.ImageWrapperInner>
            </S.ImageWrapper>
            <S.FormWrapper>
              <CreateNewJobForm />
            </S.FormWrapper>
          </S.NewJobWrapper>
              {/*
              <CreateJobForm
                redirectType="jobs"
                onCreateJob={() => {
                  controlDialog(DialogId.CREATE_NEW_JOB, false)
                  openDialog(DialogId.CREATE_SEQUENCE, 'OPTIONS')
                }}
                onMergeLinkVisibility={(isVisible: boolean) => {
                  setIsModal(!isVisible)
                }}
              />
              */}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
