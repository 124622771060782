import { useMemo } from 'react'
import { PageHeader } from 'src/components/blocks/page-header'
// import { Dropdown } from 'src/components/primitives/dropdown'
// import { Button } from 'src/components/primitives/button'
// import { PieChart } from 'src/components/charts/pie-chart'
import { ChartWrapper } from 'src/components/charts/chart-wrapper'
import { Flex } from 'src/components/primitives/flex'
import { TabularChart } from 'src/components/charts/tabular-chart'
import type { TabularChartSchema } from 'src/components/charts/tabular-chart'
import { GroupedBarChart } from 'src/components/charts/grouped-bar-chart'
import { Caption } from 'src/components/primitives/typography'
import { Avatar } from 'src/components/primitives/avatar'
import { SelectableChartGroup } from 'src/components/charts/selectable-chart-group'
import { useCandidateJobCountsQuery } from 'src/hooks/queries/use-candidate-job-counts'
import { useJobReportQuery } from 'src/hooks/queries/use-job-report'
import { groupBy } from 'lodash'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import type { OrgUser } from 'src/libs/api/backend/users'
import RouteBuilder from 'src/libs/route-builder'
import { useParams } from 'react-router'
import { EmptyState } from 'src/components/blocks/empty-state'
import * as S from './candidates.styled'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
import { ArrowRightSvg } from 'src/components/primitives/svgs'
import { CandidateJobRejectionReason, getCandidateRejectionReasonDisplay } from 'src/libs/api/backend/candidate_jobs'
import type { DataPoint as BarChartDataPoint } from 'src/components/charts/bar-chart'
import { SEO } from 'src/components/primitives/seo'
import { ReportFeedbackBanner } from 'src/components/blocks/report-feedback-banner'

interface TeamActivityChart {
  name: React.ReactNode
  sourced: React.ReactNode
  rejected: React.ReactNode
  read: React.ReactNode
  replied: React.ReactNode
}

interface BestPerformingSourcesChart {
  source: React.ReactNode
  sourced: React.ReactNode
  sequencedPct: React.ReactNode
  sequenced: React.ReactNode
  communicatingPct: React.ReactNode
  communicating: React.ReactNode
}

interface TeamActivityCount {
  userName: string
  userId: string
  opened?: number
  responded?: number
  count?: number
}

interface TeamActivityChartData {
  userName: string
  sourced: number
  rejected: number
  read: number
  replied: number
}

// TxtWithArrow
//  pct should be between 0 and 1
interface TxtWithArrowProps {
  txt: string
  theme: 'positive' | 'warning' | 'neutral'
}
const TxtWithArrow = ({ txt, theme }: TxtWithArrowProps): JSX.Element => {
  const themes = {
    positive: ['positiveFaded', 'positiveFg'],
    warning: ['warningFaded', 'warningFg'],
    neutral: ['bgTertiary', 'fgTertiary']
  }
  const [bg, fg] = themes[theme]
  return (
    <Flex
      $gap={40} $justify="center">
      <S.Relative>
        <S.AbsoluteAndCentered>
          <ArrowRightSvg width={88} />
        </S.AbsoluteAndCentered>
        <S.ChangeBadge $bgThemeColor={bg}>
          <Caption size="2XS" $color={fg} $whiteSpace="nowrap">
            {txt}
          </Caption>
        </S.ChangeBadge>
      </S.Relative>
    </Flex>
  )
}

const getTeamActivityChartData = (
  teamActivityEmailCounts: TeamActivityCount[],
  teamActivityRejectedCounts: TeamActivityCount[],
  teamActivitySourcedCounts: TeamActivityCount[],
  orgUsers: OrgUser[]
): TeamActivityChart[] => {
  // Map to hold the combined data
  const dataMap = new Map<string, TeamActivityChartData>()

  // userId -> email address
  const emailMap = new Map<string, string>()
  const avatarMap = new Map<string, string | null | undefined>()
  orgUsers.forEach((orgUser) => {
    const { id, emailAccounts, profilePhotoUrl } = orgUser
    const email = emailAccounts?.[0]?.email ?? ''
    emailMap.set(id, email)
    avatarMap.set(id, profilePhotoUrl)
  })

  // Process sourced counts
  teamActivitySourcedCounts.forEach(({ userId, userName, count }) => {
    const existing = dataMap.get(userId) ?? {
      userName,
      sourced: 0,
      rejected: 0,
      read: 0,
      replied: 0
    }
    existing.sourced = count ?? 0
    dataMap.set(userId, existing)
  })

  // Process rejected counts
  teamActivityRejectedCounts.forEach(({ userId, userName, count }) => {
    const existing = dataMap.get(userId) ?? {
      userName,
      sourced: 0,
      rejected: 0,
      read: 0,
      replied: 0
    }
    existing.rejected = count ?? 0
    dataMap.set(userId, existing)
  })

  // Process email counts
  teamActivityEmailCounts.forEach(({ userId, userName, opened, responded }) => {
    const existing = dataMap.get(userId) ?? {
      userName,
      sourced: 0,
      rejected: 0,
      read: 0,
      replied: 0
    }
    existing.read = opened ?? 0
    existing.replied = responded ?? 0
    dataMap.set(userId, existing)
  })

  // Transform the map into the desired output structure
  return Array.from(dataMap.entries()).map(
    ([userId, { userName, sourced, rejected, read, replied }]) => ({
      name: (
        <Flex $gap={8} $align="center">
          <Avatar
            $type="photo"
            $size={24}
            initials={userName.charAt(0)}
            photoUrl={avatarMap.get(userId)}
          />
          <Flex $direction="column" $gap={2}>
            <Caption size="XS">{userName}</Caption>
            {/* <Paragraph size="XS" $color="fgTertiary">
              {emailMap.get(userId) ?? ''}
            </Paragraph> */}
          </Flex>
        </Flex>
      ),
      sourced,
      rejected,
      read,
      replied
    })
  )
}

const ReportsCandidatesPage = (): JSX.Element => {
  const { jobId } = useParams()
  const { data: candidateJobCounts } = useCandidateJobCountsQuery()
  const { /* clickedEmails, */ total } = candidateJobCounts ?? {}

  const { data: jobReport, isLoading } = useJobReportQuery()

  const { data: orgUsers } = useOrgUsersQuery()

  const {
    // approvalStatusCounts,
    rejectionReasonCounts,
    candidateApprovalOverTimeCounts,
    openedOverTimeCounts,
    respondedOverTimeCounts,
    teamActivityEmailCounts,
    teamActivityRejectedCounts,
    teamActivitySourcedByCounts,
    teamActivitySourcedCounts,
    totalApproved,
    totalOpened,
    totalResponded,
    sourcedOverTimeCounts
  } = jobReport ?? {}
  const teamActivitySourcedByCountsWithPct = teamActivitySourcedByCounts?.map((source) => {
    let seqTheme: 'positive' | 'warning' | 'neutral'
    let seqTxt
    if (source.sourced === 0) {
      seqTheme = 'neutral'
      seqTxt = '–'
    } else {
      const pct = Math.round(source.sequenced / source.sourced * 100)
      seqTxt = `${pct}%`
      seqTheme = pct >= 50 ? 'positive' : 'warning'
    }

    let commTheme: 'positive' | 'warning' | 'neutral'
    let commTxt
    if (source.sequenced === 0) {
      commTheme = 'neutral'
      commTxt = '–'
    } else {
      const pct = Math.round(source.communicating / source.sequenced * 100)
      commTxt = `${pct}%`
      commTheme = pct >= 20 ? 'positive' : 'warning'
    }

    return {
      ...source,
      sequencedPct: <TxtWithArrow theme={seqTheme} txt={seqTxt} />,
      communicatingPct: <TxtWithArrow theme={commTheme} txt={commTxt} />
    }
  })
  const teamActivityChartData = useMemo(() => {
    return getTeamActivityChartData(
      teamActivityEmailCounts ?? [],
      teamActivityRejectedCounts ?? [],
      teamActivitySourcedCounts ?? [],
      orgUsers ?? []
    )
  }, [teamActivityEmailCounts, teamActivityRejectedCounts, teamActivitySourcedCounts, orgUsers])

  const sourcedOverTimeCountsData =
    sourcedOverTimeCounts?.map((sourced) => {
      return {
        label: sourced.friendlyDate,
        Manual: sourced.manual,
        AI: sourced.ai
      }
    }) ?? []

  const candidateApprovalOverTimeData =
    candidateApprovalOverTimeCounts?.map((approval) => {
      return {
        label: approval.friendlyDate,
        Approved: approval.approved,
        Archived: approval.rejected
      }
    }) ?? []

  const sourcedData = sourcedOverTimeCountsData
  const approvedOverTimeData = candidateApprovalOverTimeData
  const openedOverTimeData = openedOverTimeCounts?.map((opened) => ({
    label: opened.friendlyDate,
    Opened: opened.opened
  })) ?? []
  const respondedOverTimeData = respondedOverTimeCounts?.map((responded) => ({
    label: responded.friendlyDate,
    Replied: responded.responded
  })) ?? []

  const mergeSourcedData = (dataSets: BarChartDataPoint[][]): BarChartDataPoint[] => {
    const mergedData: Record<string, BarChartDataPoint> = {}

    dataSets.forEach(dataSet => {
      dataSet.forEach((data: BarChartDataPoint) => {
        const { label, ...metrics } = data
        if (!mergedData[label]) {
          mergedData[label] = { label, ...metrics }
        } else {
          Object.keys(metrics).forEach(metric => {
            if (typeof metrics[metric] === 'number') {
              mergedData[label][metric] = ((mergedData[label][metric] as number) || 0) + (metrics[metric] as number)
            } else {
              mergedData[label][metric] = metrics[metric]
            }
          })
        }
      })
    })
    return Object.values(mergedData)
  }

  const combinedSourcedOverTimeData = mergeSourcedData([sourcedData, approvedOverTimeData, openedOverTimeData, respondedOverTimeData])

  // const approvalStatusData =
  //   approvalStatusCounts?.map((approval) => {
  //     return {
  //       name: approval.jobStageGroup,
  //       value: approval.count
  //     }
  //   }) ?? []

  const rejectionReasonsByStage = useMemo(
    () => (rejectionReasonCounts !== undefined ? groupBy(rejectionReasonCounts, 'fromStage') : {}),
    [rejectionReasonCounts]
  )

  const stageFriendlyName = (stage: string): string => {
    switch (stage) {
      case 'SOURCED':
        return 'Sourced'
      case 'PROSPECTING':
        return 'In outreach'
      case 'COMMUNICATING':
        return 'Communications'
      default:
        return 'All'
    }
  }

  const rejectionReasonFriendlyName = (reason: string): string => {
    if (reason in CandidateJobRejectionReason) {
      return getCandidateRejectionReasonDisplay(reason as CandidateJobRejectionReason) ?? 'Unknown'
    }

    return 'Unknown'
  }

  interface RejectionReason {
    rejectionReason: string
    rejectionCount: number
  }

  const topRejectionReasonsData = useMemo(() => {
    const allReasonsMap = new Map<string, RejectionReason>()

    const topRejectionReasonsData = Object.entries(rejectionReasonsByStage).map(
      ([stage, reasons]) => {
        const currentReasonsMap = new Map<string, RejectionReason>()

        const setMap = (map: Map<string, RejectionReason>, reason: RejectionReason) => {
          const label = rejectionReasonFriendlyName(reason.rejectionReason)
          const existing = map.get(label)
          if (existing) {
            existing.rejectionCount += reason.rejectionCount
          } else {
            map.set(label, {
              rejectionReason: label,
              rejectionCount: reason.rejectionCount
            })
          }
        }

        reasons.forEach((reason) => {
          setMap(currentReasonsMap, reason)
          // Combine all reasons for all stages into a single list
          setMap(allReasonsMap, reason)
        })
        // Return the data for the current stage
        return {
          category: `${stageFriendlyName(stage)}`,
          data: Array.from(allReasonsMap.values()).map((reason) => ({
            label: reason.rejectionReason,
            value: reason.rejectionCount
          }))
        }
      }
    )

    const allReasons: RejectionReason[] = Array.from(allReasonsMap.values())

    topRejectionReasonsData.unshift({
      category: 'All',
      data: allReasons.map((reason) => ({
        label: reason.rejectionReason,
        value: reason.rejectionCount
      }))
    })

    return topRejectionReasonsData
  }, [rejectionReasonsByStage])

  const teamActivitySchema: TabularChartSchema<TeamActivityChart> = {
    name: 'Team activity',
    sourced: 'Sourced',
    rejected: 'Archived',
    read: 'Read',
    replied: 'Replied'
  }

  const bestPerformingSourcesSchema: TabularChartSchema<BestPerformingSourcesChart> = {
    source: 'Sourced By',
    sourced: 'Total',
    sequencedPct: '',
    sequenced: 'Reached Out',
    communicatingPct: '',
    communicating: 'Responded'
  }

  /* const demoData = [
   *   {
   *     category: 'All rejections',
   *     data: [
   *       {
   *         label: 'Foo',
   *         value: 8
   *       },
   *       {
   *         label: 'Bar',
   *         value: 40
   *       },
   *       {
   *         label: 'Lorem',
   *         value: 20
   *       },
   *       {
   *         label: 'ipsum',
   *         value: 12
   *       }
   *     ]
   *   }
   * ] */

  const isEmpty = useMemo(
    () =>
      !totalApproved?.approved &&
      !rejectionReasonCounts?.length &&
      !totalOpened?.opened &&
      !totalResponded?.responded,
    [totalApproved, rejectionReasonCounts, totalOpened, totalResponded]
  )

  const header = useMemo(
    () => (
      <PageHeader
        heading="Reports · Candidates"
        actions={
          undefined
          /* <>
            <Dropdown
              trigger={
                <Button
                  nested
                  leadingIcon="calendar"
                  trailingIcon="chevron-down"
                  $height={24}
                  $fontSize={12}
                  $variant="raised"
                  $colorTheme="muted"
                >
                  All time
                </Button>
              }
              items={[
                {
                  title: 'Option 1'
                },
                {
                  title: 'Option 2'
                },
                {
                  title: 'Option 3'
                }
              ]}
              menuPosition="end"
              size="small"
            />
            <Button
              leadingIcon="arrow-up-right"
              $height={24}
              $fontSize={12}
              $variant="outline"
              $colorTheme="muted"
            >
              Export PDF
            </Button>
            <Dropdown
              trigger={
                <Button
                  nested
                  ariaLabel="More"
                  $variant="ghost"
                  $colorTheme="muted"
                  leadingIcon="more-vertical"
                  $fontSize={12}
                  $height={24}
                />
              }
              items={[
                {
                  title: 'Option 1'
                },
                {
                  title: 'Option 2'
                },
                {
                  title: 'Option 3'
                }
              ]}
              menuPosition="end"
              size="small"
            />
          </> */
        }
      />
    ),
    []
  )

  if (isLoading) {
    return (<LoadingSkeleton $variant="CandidateDetailsCard" />)
  }

  if (isEmpty && !isLoading) {
    return (
      <Flex $direction="column" $height="full">
        {header}
        <EmptyState
          heading="No data to report yet"
          description="As you start approving and archiving candidates we'll generate a report for you"
          svg="report"
          $borderRadius="6px"
          $padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
          actions={[
            {
              href: RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId }),
              children: 'Go to sourcing'
            }
          ]}
        />
      </Flex>
    )
  }

  return (
    <>
      <SEO title='Candidate Reports' />
      {header}
      <Flex $direction="column" $gap={20}>
        <ReportFeedbackBanner />
        <ChartWrapper $variant="solid">
          <SelectableChartGroup
            total={total?.totalWithoutDataWarehouseSourcedCount ?? 0}
            groups={[
              {
                label: 'All activities',
                value: total?.totalWithoutDataWarehouseSourcedCount ?? 0,
                chartTitle: 'All activities over time',
                chartData: combinedSourcedOverTimeData
              },
              {
                label: 'Total sourced',
                value: total?.totalWithoutDataWarehouseSourcedCount ?? 0,
                chartTitle: 'Total sourced candidates',
                chartData: sourcedOverTimeCountsData
              },
              {
                label: 'Approved',
                value: totalApproved?.approved ?? 0,
                chartTitle: 'Candidates approval over time',
                chartData: approvedOverTimeData
              },
              {
                label: 'Opened email',
                value: totalOpened?.opened ?? 0,
                chartTitle: 'Candidates email open rate over time',
                chartData: openedOverTimeData
              },
              {
                label: 'Replied',
                value: totalResponded?.responded ?? 0,
                chartTitle: 'Replies over time',
                chartData: respondedOverTimeData
              }
            ]}
          />
        </ChartWrapper>
        <ChartWrapper>
          <GroupedBarChart
            title="Top archive reasons"
            dataKey="Candidates"
            data={topRejectionReasonsData}
          />
        </ChartWrapper>
        {/* <Flex $gap={24} $align="center" $justify="center">
          <ChartWrapper $padding={{ left: 40 }}>
            <Flex $gap={24} $align="center" $justify="center">
              <PieChart
                chartTitle="Approval status"
                data={approvalStatusData}
              />
            </Flex>
          </ChartWrapper>
          <ChartWrapper $padding={{ left: 40 }}>
            <PieChart
              chartTitle="Diversity"
              data={[
                { name: 'Rejected', value: 45 },
                { name: 'Approved', value: 55 }
              ]}
            />
          </ChartWrapper>
        </Flex> */}
        <ChartWrapper>
          <TabularChart<TeamActivityChart>
            schema={teamActivitySchema}
            tableData={teamActivityChartData}
          />
        </ChartWrapper>
        <ChartWrapper>
          <TabularChart<BestPerformingSourcesChart>
            schema={bestPerformingSourcesSchema}
            tableData={teamActivitySourcedByCountsWithPct ?? []}
          />
        </ChartWrapper>
        <div></div> {/* Hack to add padding to bottom of reports */}
      </Flex>
    </>
  )
}

export default ReportsCandidatesPage
