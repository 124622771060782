import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const ErrorLayout = styled.div`
  position: fixed;
  box-sizing: border-box;
  display: flex;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  background-color: #FCFCFD;
  padding: 80px;
`

export const Main = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

export const Content = styled.div`
  height: 100%;
  flex-grow: 1 1 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Text = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  * {
    text-align: center;
  }
  h1 {
    font-size: 14px;
    font-weight: 510;
    color: #27272A;
    margin: 6px 0;
  }
  h3 {
    font-size: 14px;
    font-weight: normal;
    color: #27272A;
    margin: 6px 0;
  }
`

export const BackLink = styled(NavLink)`
  padding: 0 8px;
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #27272A;
  text-decoration: none;
  border-radius: 4px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02), 0px 1px 4px 0px rgba(0, 0, 0, 0.10);
  margin-top: 36px;
  span {
    font-size: 14px;
    font-weight: 510;
    color: #27272A;
    line-height: 1;
  }
`

export const ErrorCode = styled.div`
  margin-top: 36px;
  p {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    * {
      font-size: 12px;
    }
    span {
      opacity: 0.6;
    }
  }
`

export const ContactLink = styled.a`
  font-size: 12px;
  color: #27272A;
  text-align: center;
  font-weight: 510;
  line-height: 1;
  letter-spacing: 0.12px; 
  border: solid 1px rgba(24, 24, 27, 0.10);
  border-radius: 4px;
  padding: 4px 8px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`

export const Pin = styled.div`
  width: 40px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 36px;
`
