import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNotification } from 'src/hooks/use-notification'
import { refreshCandidateSequenceStepMessageReviews } from 'src/libs/api/backend/candidate_sequence_step_message_review'
import type { CandidateSequenceStepMessageReview } from 'src/libs/api/backend/candidate_sequence_step_message_review'
import { queryKeys } from 'src/libs/query-keys'

interface Args {
  jobId: string
  dirtyCandidateSequenceStepMessageReviewIds: Array<CandidateSequenceStepMessageReview['id']>
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  refreshManualEmailCopy: (args: Args) => void
}

export const useRefreshManualEmailCopy = (): Res => {
  const queryClient = useQueryClient()
  const { notify } = useNotification()

  const mutation = useMutation({
    mutationFn: async ({ jobId, dirtyCandidateSequenceStepMessageReviewIds }: Args): Promise<void> => {
      await refreshCandidateSequenceStepMessageReviews({
        jobId,
        dirtyCandidateSequenceStepMessageReviewIds
      })
    },
    onError: (err, variables) => {
      console.error(err)
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred while refreshing manual email content: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateSequenceStepMessageReviews]
      })

      notify({
        type: 'toast',
        variant: 'positive',
        position: 'bottom-right',
        icon: 'check-check',
        message: 'Successfully started refreshing manual emails'
      })

      if (variables.onSuccess) {
        variables.onSuccess()
      }
    }
  })

  const refreshManualEmailCopy = (args: Args): void => {
    mutation.mutate(args)
  }

  return { refreshManualEmailCopy }
}
