import { useMutation } from '@tanstack/react-query'
import { useNotification } from '../use-notification'
import { sendTestEmail as sendTestEmailFn } from 'src/libs/api/backend/sequences'
import type { SendTestEmailArgs } from 'src/libs/api/backend/sequences'

interface Args extends SendTestEmailArgs {
  onSuccess?: () => void
}

interface Res {
  sendTestEmail: (args: Args) => void
}

export const useSendTestEmail = (): Res => {
  const { notify } = useNotification()

  const mutation = useMutation({
    mutationFn: async ({ sequenceStep, emails }: Args) => {
      await sendTestEmailFn({ emails, sequenceStep })
    },
    onError: (err) => {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred when sending the preview: ${err.message}`
      })
    },
    onSuccess: async (_, variables) => {
      if (variables.onSuccess) {
        variables.onSuccess()
      }
      notify({
        type: 'toast',
        variant: 'positive',
        position: 'bottom-right',
        icon: 'mails',
        message: 'Successfully sent test email',
        autoClose: true
      })
    }
  })

  const sendTestEmail = (args: Args): void => {
    mutation.mutate(args)
  }

  return { sendTestEmail }
}
