import * as Dialog from 'src/components/primitives/dialog'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'
import { AtsSettings } from 'src/components/blocks/ats-settings'
import * as S from './connect-ats-dialog.styled'

export const ConnectATSDialog = (): JSX.Element => {
  const { isDialogOpen, controlDialog } = useDialog()

  return (
    <Dialog.Root
      id={DialogId.CONNECT_ATS}
      isOpen={isDialogOpen(DialogId.CONNECT_ATS)}
      onOpenChange={(value) => {
        controlDialog(DialogId.CONNECT_ATS, value)
      }}
      $width="640px"
      $maxWidth="640px"
      $height="large"
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Connect your ATS"
          onClose={() => {
            controlDialog(DialogId.CONNECT_ATS, false)
          }}
        />
        <Dialog.Content>
          <S.Inner>
            <AtsSettings integrationsColumnsCount={2} />
          </S.Inner>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
