import styled, { keyframes, css } from 'styled-components'

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.1; }
`

const generateAnimationDelays = (count: number = 50, defaultDelay: number = 0.1) => {
  let styles = ''
  for (let i = 0; i < count; i++) {
    styles += `
      &:nth-child(${i + 1}) {
        animation-delay: ${defaultDelay * i}s;
      }
    `
  }
  return css`
    ${styles}
  `
}

export const CandidateDetailsCard = styled.div`
  display: flex;
  height: 220px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  animation: ${blink} 1s linear infinite;
  margin-bottom: 1rem;
`

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`

export const TableHead = styled.div`
  width: 100%;
  height: 36px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  animation: ${blink} 1s linear infinite;
`

export const TableRow = styled.div`
  border-radius: 4px;
  height: 70px;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  &:nth-child(odd) {
    animation: ${blink} 1s linear infinite;
  }
  &:nth-child(even) {
    animation: ${blink} 1s linear infinite;
    animation-delay: 0.2s;
  }
`

export const EmailSequenceEditor = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[24]};
`

export const EmailSequenceSteps = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 33%;
  max-width: 280px;
  gap: 1rem;
`

export const EmailSequenceStep = styled.div`
  border-radius: 4px;
  height: 120px;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  &:nth-child(odd) {
    animation: ${blink} 1s linear infinite;
  }
  &:nth-child(even) {
    animation: ${blink} 1s linear infinite;
    animation-delay: 0.2s;
  }
`

export const EmailSequenceWindow = styled.div`
  flex: 0 1 67%;
  height: 35vh;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  animation: ${blink} 1s linear infinite;
`

export const Inbox = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;
  padding-left: 1rem;
`

export const InboxItem = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  height: 8rem;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  border-radius: 0.25rem;
  animation: ${blink} 1s linear infinite;
  &:nth-child(even) {
    animation-delay: 0.2s;
  }
  ${generateAnimationDelays(50, 0.05)}
`

export const InboxSidebar = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  overflow: hidden;
`

export const InboxSidebarItem = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  height: 3.625rem;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  border-radius: 0.25rem;
  animation: ${blink} 1s linear infinite;
  &:nth-child(even) {
    animation-delay: 0.2s;
  }
  ${generateAnimationDelays(50, 0.05)}
`

export const JobsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[6]};
  width: 100%;
  flex: 1;
`

export const JobCard = styled.div`
  height: 66px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  animation: ${blink} 1s linear infinite;
`

export const Text = styled.div`
  width: 100%;
  height: 1rem;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  animation: ${blink} 1s linear infinite;
`

export const NavigationItem = styled(Text)`
  background-color: ${({ theme }) => theme.colors.fgFaded10};
`

export const Header = styled(Text)`
  background-color: ${({ theme }) => theme.colors.fgFaded10};
  height: 1.5rem;
  max-width: 200px;
`

export const SelectContainer = styled.div<{ $marginTop?: number, $marginBottom?: number }>`
  margin-top: ${(props) => props.$marginTop ?? 0}rem;
  margin-bottom: ${(props) => props.$marginBottom ?? 0}rem;
  padding: 0.625rem;
  border: 1px solid ${({ theme }) => theme.colors.fgFaded10};
  border-radius: 0.25rem;
  width: 100%;
`

export const Select = styled(Text)`
  background-color: ${({ theme }) => theme.colors.fgFaded10};
  height: 1.5rem;
  width: 100%;
`

export const SenderSelectContainer = styled.div`
  max-width: 8rem;
  width: 100%;
  height: 1.5rem;
  opacity: 0.8;
  padding: .25rem;
  border: 1px solid ${({ theme }) => theme.colors.fgFaded10};
  border-radius: 0.25rem;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02), 0px 1px 4px 0px rgba(0, 0, 0, 0.10);
  color: ${({ theme }) => theme.colors.fgPrimary};
`

export const SenderSelect = styled(Select)`
  height: .75rem;
  width: 100%;
`
