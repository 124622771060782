import { useQuery } from '@tanstack/react-query'
import { getJobSearchRefinementHistory } from '../../libs/api/backend/jobs'

export function useJobSearchRefinementSearchHistory ({ jobId, jobSearchRefinementId }: {
  jobId?: string
  jobSearchRefinementId?: string
}): { searchHistory: string[] } {
  const { data } = useQuery({
    queryKey: ['getJobSearchRefinementHistory', {
      jobId,
      jobSearchRefinementId
    }] as const,
    queryFn: async (ctx) => {
      const { jobId, jobSearchRefinementId } = ctx.queryKey[1]
      const items = await getJobSearchRefinementHistory({
        jobId: jobId ?? '',
        jobSearchRefinementId: jobSearchRefinementId ?? ''
      })
      return items.map(item => item.searchQuery)
    },
    enabled: !!jobId && !!jobSearchRefinementId
  })

  return {
    searchHistory: data ?? []
  }
}
