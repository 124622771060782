import { isNil } from 'lodash'
import type { RefinementHighlight } from 'src/libs/api/backend/jobs'

export const parseEditorContent = (input: string, highlights: RefinementHighlight[]): string => {
  let content = input
  // Reverse highlights from last position to first and ignore overlapped
  const orderHighlightsFromLastToFirst =
    highlights
      .filter((highlight) => !isNil(highlight.position) && highlight.position.length === 2)
      .sort((a, b) => {
        const [, endA] = a.position ?? [0, 0]
        const [, endB] = b.position ?? [0, 0]
        return endB - endA
      })
  let startPosition = Infinity
  // Just in case the BE missed some overlapped
  const noOverlappedHighlights = orderHighlightsFromLastToFirst.reduce((acc: RefinementHighlight[], highlight: RefinementHighlight) => {
    if (highlight.position?.length === 2) {
      const [start, end] = highlight.position
      const isOverlapped = startPosition < end
      if (!isOverlapped) {
        acc.push(highlight)
        startPosition = start
      }
    }
    return acc
  }, [])
  noOverlappedHighlights.forEach((highlight) => {
    const { position, supported, name, value, originalWords } = highlight
    const [start, end] = position ?? [0, 0] // Default value to avoid errors but should never happen
    // Extract word from content from start to end position
    const word = content.slice(start, end)
    if (!word || word !== originalWords[0]) {
      console.info('Refinement highlight word and position DO NOT match position: ', highlight.position, ' original: ', originalWords, ' found: ', word)
    } else {
      const data = {
        supported,
        text: word,
        name,
        value: Array.isArray(value) ? value[0] : value
      }

      // Replace from start to end with the component
      content = content.slice(0, start) + `<refinement-component data='${JSON.stringify(data)}'>${word}</refinement-component>` + content.slice(end)
    }
  })
  const isLastCharacterAHighlight = noOverlappedHighlights[0]?.position?.[1] === input.length
  // This is to escape the NodeViewWrapper in Tiptap
  return `${content}${isLastCharacterAHighlight ? '&nbsp;' : ''}`
}
