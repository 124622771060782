import { useEffect, useRef, useState } from 'react'
import { When } from '../when'
import { DownloadChromeExtensionBanner } from '../download-chrome-extension-banner/download-chrome-extension-banner'
import { usePinExtensionVersion } from 'src/hooks/use-pin-extension'
import { Spacer } from 'src/components/primitives/spacer'
import { CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'
import { useCreateCandidateByLinkedIn } from 'src/hooks/mutations/use-create-candidate-by-linkedin'
import { CandidateStageSelectionOptions } from '../candidate-stage-selection-options'
import { Form, Textarea } from 'src/components/forms'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'
import { useForm } from 'src/hooks/use-form'
import { z } from 'zod'
import { FieldError } from 'src/components/forms/field-error'
import { FormActionButtons } from '../form-action-buttons'
import { Caption } from 'src/components/primitives/typography'
import { validateLinkedInUrl } from 'src/libs/validations'
import { isNil } from 'lodash'
import { FileDropUpload } from 'src/components/forms/file-drop-upload'
import { VisualDivider } from 'src/components/primitives/visual-divider'
import { useCreateCandidatesFromCsv } from 'src/hooks/mutations/use-create-candidates-from-csv'

const candidateSchema = z.object({
  linkedinUrls: z.string().nullish()
})

export type CandidateSchema = z.infer<typeof candidateSchema>

export const AddCandidateForm = (): JSX.Element => {
  const { closeDialog } = useDialog()
  const { createCandidatesByLinkedIn } = useCreateCandidateByLinkedIn()
  const { createCandidatesFromCsv } = useCreateCandidatesFromCsv()
  const [uploadedFilesS3Urls, setUploadedFilesS3Urls] = useState<string[] | null>(null)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [destinationStage, setDestinationStage] = useState<CandidateJobStage>(
    CandidateJobStage.SOURCED
  )

  const inputRef = useRef<HTMLTextAreaElement>(null)

  const extensionVersion = usePinExtensionVersion()

  const { submit, register } = useForm<CandidateSchema>({
    schema: candidateSchema
  })

  const handleSubmit = async (formData: CandidateSchema): Promise<void> => {
    setIsSubmitting(true)
    const urls = formData?.linkedinUrls?.split(/[\s,\n]+/)
    const validated = urls?.map((url) => validateLinkedInUrl(url)).filter((url) => !isNil(url))
    const validatedUrls = validated?.filter((url): url is string => url !== null) ?? []

    if (!isNil(uploadedFilesS3Urls) && uploadedFilesS3Urls?.length >= 1) {
      setError(null)
      createCandidatesFromCsv({
        csvS3Key: uploadedFilesS3Urls[0],
        stage: destinationStage,
        onSuccess: () => {
          closeDialog(DialogId.ADD_CANDIDATE)
          setIsSubmitting(false)
          setUploadedFilesS3Urls(null)
        }
      })
    } else {
      if ((validatedUrls?.length ?? 0) >= 1) {
        setError(null)
        createCandidatesByLinkedIn({
          linkedins: validatedUrls,
          stage: destinationStage,
          onSuccess: () => {
            closeDialog(DialogId.ADD_CANDIDATE)
            setIsSubmitting(false)
          }
        })
      } else {
        setError('Please add valid LinkedIn urls')
        setIsSubmitting(false)
      }
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  return (
    <>
      <When condition={!extensionVersion}>
        <DownloadChromeExtensionBanner />
        <Spacer $size={28} />
      </When>
      <Caption size="SM" $color="fgSecondary">
        Where do you want to add the new candidates
      </Caption>
      <Spacer $size={16} />
      <CandidateStageSelectionOptions
        onStageChange={(stage) => {
          setDestinationStage(stage)
        }}
      />
      <Spacer $size={24} />
      <FileDropUpload
        heading="Upload CSV file"
        accept={['csv']}
        onUpload={(urls) => {
          setUploadedFilesS3Urls(urls)
        }}
      />
      <VisualDivider $spacingTop={24} $spacingBottom={24}>Or</VisualDivider>
      <Form onSubmit={submit(handleSubmit)}>
        <Textarea
          ref={inputRef}
          name="linkedinUrls"
          placeholder="https://www.linkedin.com/in/username"
          label="Candidate LinkedIn URLs"
          rows={4}
          register={register}
          $marginBottom={0}
        />
        {error && <FieldError>{error}</FieldError>}
        <FormActionButtons
          saveText="Add Candidates"
          cancelText="Cancel"
          onCancel={() => {
            closeDialog(DialogId.ADD_CANDIDATE)
          }}
          isSaving={isSubmitting}
        />
      </Form>
    </>
  )
}
