import { Button } from 'src/components/primitives/button'
import { Flex } from 'src/components/primitives/flex'
import { useRejectCandidate } from 'src/hooks/mutations/use-reject-candidate'
import { CandidateJobStage, sourcingRejectionReasons } from 'src/libs/api/backend/candidate_jobs'
import type {
  CandidateJobRejectionReason,
  CandidateJobExpanded
} from 'src/libs/api/backend/candidate_jobs'
import { Dropdown } from 'src/components/primitives/dropdown'
import { ButtonGroup } from 'src/components/primitives/button-group'
import { useToggleCandidateFavoriteStatus } from 'src/hooks/mutations/use-toggle-candidate-favorite-status'
import { map } from 'lodash'
import { Badge } from 'src/components/primitives/badge'
import { Icons } from 'src/components/primitives/icon'
import { useDialog } from 'src/hooks/use-dialog'
import { useCallback } from 'react'
import { DialogId } from 'src/contexts/dialogs'
import { isSequenceStepsEmpty } from 'src/libs/sequence'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { useSession } from 'src/hooks/use-session'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { sleep } from 'src/libs/sleep'

interface CandidateActionsProps {
  candidateJob: CandidateJobExpanded
  onAddToSequenceClick: () => void
  isAddingToSequence?: boolean
  onUndoClick?: () => void
}

export const CandidateActions = ({
  candidateJob,
  onAddToSequenceClick,
  isAddingToSequence = false,
  onUndoClick
}: CandidateActionsProps): JSX.Element => {
  const { rejectCandidate } = useRejectCandidate()
  const { toggleFavoriteStatus } = useToggleCandidateFavoriteStatus()
  const { openDialog } = useDialog()

  const { data: emailSequence, isLoading: isJobSequenceLoading } = useJobSequenceQuery()

  const { featureFlags } = useSession()

  const stage = candidateJob.stage
  const isFavorited = candidateJob.favorite

  const handleReject = (rejectionReason?: CandidateJobRejectionReason): void => {
    rejectCandidate({ candidateJobIds: [candidateJob.id], rejectionReason })
  }

  const handleToggleFavoriteStatus = (newStatus: boolean): void => {
    toggleFavoriteStatus({
      candidateJobId: candidateJob.id,
      newFavoriteStatus: newStatus
    })
  }

  const onMoveCandidatesClick = useCallback(() => {
    const payload = {
      candidateJobs: [candidateJob],
      requiredReason: candidateJob.stage !== CandidateJobStage.SOURCED
    }
    openDialog(DialogId.MOVE_TO_ANOTHER_JOB, payload)
  }, [candidateJob, openDialog])

  const onCustomizeOutreachClick = useCallback(async () => {
    if (isSequenceStepsEmpty(emailSequence)) {
      openDialog(DialogId.CREATE_SEQUENCE)
    } else {
      await sleep(50)
      openDialog(DialogId.WRITE_CUSTOM_OUTREACH, candidateJob)
    }
  }, [emailSequence, openDialog, candidateJob])

  if (stage === 'PROSPECTING') {
    return (
      <Flex $width="auto" $gap={12} $align="center">
        <Button
          $variant="ghost"
          $colorTheme="muted"
          $height={32}
          leadingIcon={Icons.undo2}
          $fontSize={12}
          onClick={onUndoClick}
        >
          Undo
        </Button>
        <Badge $variant="positiveLight" leadingIcon={Icons.mailCheck} $height={32}>
          Added to outreach
        </Badge>
        <Button
          ariaLabel="Toggle favorite status"
          $variant="outline"
          $colorTheme={isFavorited ? 'warning' : 'muted'}
          $height={32}
          $width={32}
          leadingIcon={isFavorited ? Icons.starFill : Icons.star}
          // TODO: Hook up this up to the actual current candidate status
          onClick={() => {
            handleToggleFavoriteStatus(!isFavorited)
          }}
        />
      </Flex>
    )
  }

  return (
    <Flex $justify="flex-end" $gap={12}>
      <Button
        ariaLabel="Toggle favorite status"
        $variant="outline"
        $colorTheme={isFavorited ? 'warning' : 'muted'}
        $fontSize={12}
        $height={24}
        // $width={32}
        leadingIcon={isFavorited ? Icons.starFill : Icons.star}
        // TODO: Hook up this up to the actual current candidate status
        onClick={() => {
          handleToggleFavoriteStatus(!isFavorited)
        }}
      >
        Shortlist
      </Button>
      <ButtonGroup $variant="raised" $gap={0}>
        <Button
          $variant="raised"
          $colorTheme="tint"
          $fontSize={12}
          $height={24}
          leadingIcon={Icons.mailPlus}
          onClick={onAddToSequenceClick}
          loading={isAddingToSequence}
        >
          Add to Outreach
        </Button>
        {featureFlags?.includes(FeatureFlags.CUSTOM_OUTREACH) && !isSequenceStepsEmpty(emailSequence) &&
          <Dropdown
            trigger={
              <Button
                nested
                $fontSize={12}
                $width={24}
                $variant="raised"
                $colorTheme="tint"
                $height={24}
                leadingIcon={Icons.chevronDown}
              />
            }
            items={
              [
                {
                  id: '0',
                  title: 'Add to Outreach',
                  subTitle: 'Automated outreach sequence',
                  onSelect: onAddToSequenceClick
                },
                {
                  id: '1',
                  title: 'Customize Outreach',
                  subTitle: 'Customize 1st outreach email',
                  isDisabled: isJobSequenceLoading,
                  onSelect: onCustomizeOutreachClick
                }
              ]
            }
            size="small"
            menuPosition="end"
          />}
      </ButtonGroup>
      <ButtonGroup $variant="raised" $gap={0}>
        <Button
          $variant="raised"
          $colorTheme="negative"
          $fontSize={12}
          $height={24}
          leadingIcon={Icons.xOctagon}
          onClick={() => {
            handleReject()
          }}
        >
          Decline
        </Button>
        <Dropdown
          trigger={
            <Button
              nested
              $fontSize={12}
              $width={24}
              $variant="raised"
              $colorTheme="negative"
              $height={24}
              leadingIcon={Icons.chevronDown}
            />
          }
          items={
            map(sourcingRejectionReasons, (reason, key) => ({
              id: key,
              title: reason,
              onSelect: () => {
                handleReject(key as CandidateJobRejectionReason)
              }
            }))
          }
          size="small"
          menuPosition="end"
        />
      </ButtonGroup>
      <Dropdown
        trigger={
          <Button
            nested
            $fontSize={12}
            $width={24}
            $variant="outline"
            $colorTheme="muted"
            $height={24}
            $borderless={true}
            leadingIcon={Icons.moreVertical}
          />
        }
        size="small"
        menuPosition="end"
        items={[
          {
            id: 'move-to-another-job',
            type: 'item',
            icon: Icons.arrowRightCircle,
            title: 'Move to another job',
            closeOnSelect: true,
            onSelect: () => {
              onMoveCandidatesClick()
            }
          }
        ]}
      />

      {/* <Dropdown
        trigger={
          <Button
            nested
            $variant="ghost"
            $colorTheme="muted"
            $width={32}
            $height={32}
            leadingIcon="more-vertical"
          />
        }
        items={[
          {
            title: 'Option 1'
          },
          {
            title: 'Option 2'
          },
          {
            title: 'Option 3'
          }
        ]}
        menuPosition="end"
      /> */}
    </Flex>
  )
}
