import styled from 'styled-components'

export const Editor = styled.div`
  height: fit-content;
  max-height: 30vh;
  background-color: transparent;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  padding: 0 4px;
  * {
    font-size: ${({ theme }) => theme.fontSizes[14]};
    letter-spacing: ${({ theme }) => theme.letterSpacings['0.12']};
    outline: none;
    line-height: 20px;
    font-weight: ${({ theme }) => theme.fontWeights[400]};
    .editor-paragraph {
      line-height: 20px;
      color: ${({ theme }) => theme.colors.fgPrimary};
    }
    .editor-refinement-block {
      display: inline-block;
      width: fit-content;
    }
    .editor-refinement-highlight {
      display: inline-block;
      position: relative;
      white-space: nowrap;
      word-break: keep-all;
      color: ${({ theme }) => theme.colors.tintBg};
      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 1px;
        right: -1px;
        left: 0;
        border-bottom: 1px dashed;
        border-color: ${({ theme }) => theme.colors.tintBg};
        pointer-events: none;
      }
    }
    .editor-refinement-unmatched-highlight {
      display: inline-block;
      position: relative;
      white-space: nowrap;
      word-break: keep-all;
      color: ${({ theme }) => theme.colors.fgSecondary};
      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 1px;
        left: 0;
        right: -1px;
        border-radius: 2px;
        border-bottom: 1px dashed;
        border-color: ${({ theme }) => theme.colors.fgSecondary};
        pointer-events: none;
      }
    }
    .editor-placeholder {
      position: relative;
      background-color: transparent;
      font-size: ${({ theme }) => theme.fontSizes[12]};
      font-weight: ${({ theme }) => theme.fontWeights[400]};
      color: ${({ theme }) => theme.colors.fgSecondary};
      margin: 0 0.35rem 0 0.25rem;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: -1px;
        left: -4px;
        width: calc(100% + 6px);
        height: calc(100% + 2px);
        border-radius: 4px;
        border: solid 1px;
        border-color: ${({ theme }) => theme.colors.fgTranslucent10};
        pointer-events: none;
      }
    }
    p.is-editor-empty:first-child::before {
      font-size: ${({ theme }) => theme.fontSizes[14]};
      font-weight: ${({ theme }) => theme.fontWeights[400]};
      color: ${({ theme }) => theme.colors.fgTertiary};
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
  }
`
