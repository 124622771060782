import styled from 'styled-components'

export const EmailComposer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

export const EmailFields = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Addressbar = styled.div`
  padding: 0.75rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const AddressRow = styled.div`
  display: grid;
  grid-template-columns: 3rem 1fr;
  align-items: center;
`

export const Subjectline = styled.div`
  padding: 0.35rem 0.75rem 0.4rem 0.75rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
`

export const Toolbar = styled.div`
  width: 100%;
  // height: 2.5rem;
  display: flex;
  align-items: center;
  border-top: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  z-index: 2;
`

export const ToolbarActions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.75rem;
`

export const Generating = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  background: linear-gradient(
    90deg,
    rgba(0, 167, 255, 0.05) 0%,
    rgba(26, 180, 132, 0.05) 49.64%,
    rgba(131, 113, 242, 0.05) 100%
  );
`
