import { CandidatesPageHeader } from 'src/components/blocks/candidates-page-header'
import { CandidatesTablePagesContentInner, CandidatesTablePagesHeader } from './candidates.styled'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'
import { When } from 'src/components/blocks/when'
import { EmptyStateArchivedJob } from 'src/components/blocks/empty-state-archived-job'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { CandidatesShortlistedTable } from 'src/components/tables/candidates-shortlisted-table'
import { SEO } from 'src/components/primitives/seo'

interface Props {
  heading?: string
}

const JobCandidatesShortlistedTablePage = ({ heading = 'Shortlisted' }: Props): JSX.Element => {
  const { openDialog } = useDialog()
  const { data: job } = useJobQuery()

  return (
    <>
      <SEO title="Shortlisted Candidates" />

      <CandidatesTablePagesContentInner>
        <CandidatesTablePagesHeader>
          <CandidatesPageHeader
            heading={heading}
            actions={[
              {
                children: 'Add Candidate',
                icon: 'plus',
                // $variant: 'outline',
                $colorTheme: 'tint',
                onClick: () => {
                  openDialog(DialogId.ADD_CANDIDATE)
                }
              }
            ]}
          />
        </CandidatesTablePagesHeader>
        <When condition={Boolean(job?.deleted)}>
          <EmptyStateArchivedJob />
        </When>
        <When condition={!job?.deleted}>
          <CandidatesShortlistedTable />
        </When>
      </CandidatesTablePagesContentInner>
    </>
  )
}

export default JobCandidatesShortlistedTablePage
