import { Button } from 'src/components/primitives/button'
import { Dropdown } from 'src/components/primitives/dropdown'
import { useRejectCandidate } from 'src/hooks/mutations/use-reject-candidate'
import { CandidateJobStatus, exportCandidateJobsToCsv, inboxRejectionReasons } from 'src/libs/api/backend/candidate_jobs'
import type {
  CandidateJobExpanded,
  CandidateJobRejectionReason
} from 'src/libs/api/backend/candidate_jobs'
import { isNil, map } from 'lodash'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'
import { Icons } from 'src/components/primitives/icon'
import { usePauseCandidateSequence } from 'src/hooks/mutations/use-pause-candidate-job-sequence'
import { useUndoStageTransition } from 'src/hooks/mutations/use-undo-stage-transition'
import type { Job } from 'src/libs/api/backend/jobs'
import { useParams } from 'react-router-dom'
import { useNotification } from 'src/hooks/use-notification'
import { useSession } from 'src/hooks/use-session'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { useQueryParams } from 'src/hooks/use-query-params'
import { SettingParams } from 'src/components/dialogs/default-settings-dialog'

interface BaseProps {
  selectedRows: Record<string, boolean>
}

interface PauseResumeSequenceButtonProps extends BaseProps {
  isPause?: boolean
  onResetSelectedRows?: () => void
}

export const PauseResumeSequenceButton = ({
  selectedRows,
  isPause = true,
  onResetSelectedRows
}: PauseResumeSequenceButtonProps): JSX.Element => {
  const { setCandidateSequencePause } = usePauseCandidateSequence()

  return (
    <Button
      $variant="outline"
      $colorTheme="tint"
      $height={24}
      $fontSize={12}
      leadingIcon={Icons.pauseCircle}
      onClick={() => {
        setCandidateSequencePause({
          candidateJobIds: Object.keys(selectedRows),
          pause: isPause,
          onSuccess: () => {
            if (onResetSelectedRows) {
              onResetSelectedRows()
            }
          }
        })
      }}
    >
      {isPause ? 'Pause' : 'Unpause'} outreach
    </Button>
  )
}

interface RejectButtonProps extends BaseProps {
  onResetSelectedRows?: () => void
}

export const RejectButton = ({
  selectedRows,
  onResetSelectedRows
}: RejectButtonProps): JSX.Element => {
  const { rejectCandidate } = useRejectCandidate()
  return (
    <Dropdown
      trigger={
        <Button
          nested
          $variant="outline"
          $colorTheme="negative"
          $height={24}
          $fontSize={12}
          leadingIcon={Icons.xOctagon}
          trailingIcon="chevron-down"
        >
          Archive candidate{Object.keys(selectedRows).length >= 2 && 's'}
        </Button>
      }
      items={map(inboxRejectionReasons, (reason, key) => ({
        id: key,
        title: reason,
        onSelect: () => {
          rejectCandidate({
            candidateJobIds: Object.keys(selectedRows),
            rejectionReason: key as CandidateJobRejectionReason
          })
          if (onResetSelectedRows) {
            onResetSelectedRows()
          }
          // setSelectedRows({})
        }
      }))}
      size="small"
    />
  )
}

interface WriteEmailButtonProps extends BaseProps {
  candidateJobs?: CandidateJobExpanded[]
}

export const WriteEmailButton = ({
  selectedRows,
  candidateJobs
}: WriteEmailButtonProps): JSX.Element => {
  const { openDialog } = useDialog()

  const areSelectedCandidatesInvalid = (): boolean => {
    const invalidArguments = [CandidateJobStatus.EMAIL_NOT_FOUND, CandidateJobStatus.BOUNCED]
    const selectedCandidateJobs = candidateJobs?.filter((candidateJob) =>
      Object.keys(selectedRows).includes(candidateJob.id)
    )
    return Boolean(
      selectedCandidateJobs?.every(
        (candidateJob) =>
          candidateJob.statusDisplay?.status &&
          invalidArguments.includes(candidateJob.statusDisplay.status)
      )
    )
  }

  return (
    <Button
      $variant="outline"
      $colorTheme="normal"
      $height={24}
      $fontSize={12}
      leadingIcon={Icons.mail}
      disabled={areSelectedCandidatesInvalid()}
      onClick={() => {
        const candidateJobIds = Object.keys(selectedRows)
        const payload = candidateJobs?.filter((candidate) => candidateJobIds.includes(candidate.id))
        openDialog(DialogId.WRITE_EMAIL, payload)
      }}
    >
      Write email{Object.keys(selectedRows).length >= 2 && 's'}
    </Button>
  )
}

interface UndoRejectButtonProps extends BaseProps {}

export const UndoRejectButton = ({ selectedRows }: UndoRejectButtonProps): JSX.Element => {
  const { undoStageTransition } = useUndoStageTransition({ isRestoringCandidates: true })

  return (
    <Button
      $variant="outline"
      $colorTheme="normal"
      $height={24}
      $fontSize={12}
      leadingIcon={Icons.undo}
      onClick={() => {
        undoStageTransition(Object.keys(selectedRows))
      }}
    >
      Restore candidate{Object.keys(selectedRows).length >= 2 && 's'}
    </Button>
  )
}

interface MoveToAnotherJobProps extends BaseProps {
  candidateJobs?: CandidateJobExpanded[]
}

export const MoveToAnotherJobButton = ({
  selectedRows,
  candidateJobs
}: MoveToAnotherJobProps): JSX.Element => {
  const { openDialog } = useDialog()

  return (
    <Button
      $variant="outline"
      $colorTheme="normal"
      $height={24}
      $fontSize={12}
      leadingIcon={Icons.arrowRightCircle}
      onClick={() => {
        const candidateJobIds = Object.keys(selectedRows)
        const payload = {
          candidateJobs: candidateJobs?.filter((candidate) =>
            candidateJobIds.includes(candidate.id)
          ),
          requiredReason: true
        }
        openDialog(DialogId.MOVE_TO_ANOTHER_JOB, payload)
      }}
    >
      Move to another job
    </Button>
  )
}

interface ExportToAtsButtonProps extends BaseProps {
  candidateJobs?: CandidateJobExpanded[]
  job?: Job
}

export const ExportToAtsButton = ({
  selectedRows,
  candidateJobs,
  job
}: ExportToAtsButtonProps): JSX.Element => {
  const { openDialog } = useDialog()
  const { org, featureFlags } = useSession()
  const { setParam } = useQueryParams()

  if (!featureFlags?.includes(FeatureFlags.ATS_INTEGRATION) && !isNil(job)) {
    return <></>
  }

  return (
    <Button
      $variant="outline"
      $colorTheme="normal"
      $height={24}
      $fontSize={12}
      leadingIcon={Icons.share}
      onClick={() => {
        if (isNil(org?.mergeAtsIntegration)) {
          setParam('settings', SettingParams.ATS)
          openDialog(DialogId.DEFAULT_SETTINGS)
        } else {
          const candidateJobIds = Object.keys(selectedRows)
          const payload = {
            job,
            candidateJobs: candidateJobs?.filter((candidate) => candidateJobIds.includes(candidate.id)),
            requiredReason: true
          }
          openDialog(DialogId.EXPORT_CANDIDATES, payload)
        }
      }}
    >
      Export to ATS
    </Button>
  )
}

export const ExportToCsvButton = (): JSX.Element => {
  const { jobId } = useParams()
  const { notify } = useNotification()

  const handleDownload = async (): Promise<void> => {
    try {
      await exportCandidateJobsToCsv({ jobId: jobId ?? '' })
      notify({
        type: 'toast',
        variant: 'positive',
        position: 'bottom-right',
        icon: 'check-check',
        message: 'Saved CSV into your downloads folder',
        autoClose: true
      })
    } catch (error) {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: 'Unfortunately, there was an error downloading this file. If they error occurs, please get in touch.'
      })
    }
  }

  return (
    <Button
      $variant="outline"
      $colorTheme="normal"
      $height={24}
      $fontSize={12}
      leadingIcon={Icons.fileDown}
      onClick={handleDownload}
    >
      Export to CSV
    </Button>
  )
}
