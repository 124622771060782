import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import { fetchMergeJobs } from 'src/libs/api/backend/merge'
import type { MergeAtsJob } from 'src/libs/api/backend/merge'

interface GetMergeJobsResponse {
  mergeJobs: MergeAtsJob[]
  loading: boolean
  error: Error | null
  refetch: () => unknown
  isFetching: boolean
  isRefetching: boolean
}

export const getMergeJobs = () => ({
  queryKey: [queryKeys.mergeJobs],
  queryFn: async () => await fetchMergeJobs(),
  staleTime: 15 * 1000
})

export const useGetMergeJobs = (): GetMergeJobsResponse => {
  const { data, isLoading, error, refetch, isFetching, isRefetching } = useQuery(getMergeJobs())
  return {
    mergeJobs: data ?? [],
    loading: isLoading,
    error,
    refetch,
    isFetching,
    isRefetching
  }
}
