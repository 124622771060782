import { useEffect, useState } from 'react'
import type { CreateNewPositionForm } from './types/create-new-position-form'
import { useIsMounted } from 'src/hooks/use-is-mounted'
import { isNil } from 'lodash'
import { When } from '../when'
import { LoadingBox } from '../loading-box'
import { JobDetailsForm } from '../job-details-form'
import { extractIdealCandidate, extractJobRequirements } from 'src/libs/api/backend/gpt'
import type { ExtractJobRequirementsInput } from 'src/libs/api/backend/gpt'

interface StartProps {
  onSubmitClick?: (data: CreateNewPositionForm) => void
  importedJob: ExtractJobRequirementsInput | null
}

export const Create = ({ onSubmitClick, importedJob }: StartProps): JSX.Element => {
  const [isGenerating, setIsGenerating] = useState(true)
  const [jobData, setJobData] = useState<Partial<CreateNewPositionForm> | null>(null)

  const initialValues = {
    diverseHire: false,
    candidateDescription: ''
  }

  const isMounted = useIsMounted()

  useEffect(() => {
    const onMount = async (): Promise<void> => {
      if (!isNil(importedJob)) {
        setJobData((prev) => ({
          ...prev,
          title: importedJob.title,
          jobBoardDescription: importedJob.content
        }))
      } else {
        setIsGenerating(false)
      }

      await Promise.all([fetchJobRequirements(), fetchCandidateDescription()])
    }

    if (isMounted()) {
      void onMount()
    }
  }, [isMounted])

  const handleSubmit = async (jobData: CreateNewPositionForm): Promise<void> => {
    if (onSubmitClick) {
      onSubmitClick(jobData)
    }
  }

  const fetchJobRequirements = async (): Promise<void> => {
    if (isNil(importedJob)) {
      return
    }

    const parsedResponse = await extractJobRequirements(importedJob)
    if (isNil(parsedResponse)) {
      console.error('Error while extracting information from job listing')
      return
    }

    const { location, salaryMin, salaryMax, workspace, title } = parsedResponse
    if (!isNil(location)) {
      setJobData((prev) => ({
        ...prev,
        locations: [location]
      }))
    }

    if (!isNil(workspace)) {
      setJobData((prev) => ({
        ...prev,
        workspace
      }))
    }

    if (!isNil(salaryMin) && !isNil(salaryMax)) {
      setJobData((prev) => ({
        ...prev,
        salaryMin,
        salaryMax
      }))
    }

    if (!isNil(title)) {
      setJobData((prev) => {
        if (isNil(prev?.title)) {
          return {
            ...prev,
            title
          }
        }

        return prev
      })
    }
  }

  const fetchCandidateDescription = async (): Promise<void> => {
    if (isNil(importedJob)) {
      return
    }

    const response = await extractIdealCandidate(importedJob)
    if (isNil(response)) {
      console.error('Error while generating candidate description')
      return
    }

    const { description } = response
    if (!isNil(description)) {
      setJobData((prev) => ({
        ...prev,
        description
      }))
    }
    setIsGenerating(false)
  }

  return (
    <>
      <When condition={isGenerating}>
        <LoadingBox loadingDelayMS={0}>Analyzing job posting...</LoadingBox>
      </When>
      <When condition={!isGenerating}>
        <JobDetailsForm
          initialValues={initialValues}
          jobData={jobData}
          onSubmit={(jobData) => { void handleSubmit(jobData) }}
          isCreating={true}
        />
      </When>
    </>
  )
}
