import * as S from './error.styled'
import { HomeIcon } from 'lucide-react'

export const ErrorLayout = ({ children }: { children?: React.ReactNode }): JSX.Element => {
  return (
    <S.ErrorLayout id="error-page">
      <S.Main>
        <S.Content>
          <S.Pin>
            <svg
              width={40}
              height={43}
              viewBox="0 0 40 43"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <ellipse
                cx="19"
                cy="41"
                rx="12"
                ry="2"
                fill="#18181B"
                fillOpacity="0.1"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M37.8206 17.6606C37.8206 27.4142 29.9139 35.3211 20.1604 35.3211V39.0911C20.1604 39.694 19.5932 40.1368 19.0165 39.9612C10.0375 37.2276 3.35657 29.2199 2.58182 19.5464C2.57747 19.4922 2.5781 19.4388 2.58329 19.3868C2.52819 18.8188 2.5 18.243 2.5 17.6606C2.5 7.9069 10.4068 0 20.1603 0C29.9138 0 37.8206 7.9069 37.8206 17.6606Z"
                fill="#27272A"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.1601 29.735C26.8291 29.735 32.2354 24.3286 32.2354 17.6595C32.2354 10.9904 26.8291 5.58398 20.1601 5.58398C13.491 5.58398 8.08472 10.9904 8.08472 17.6595C8.08472 24.3286 13.491 29.735 20.1601 29.735Z"
                fill="white"
              />
              <path
                d="M22.221 15.2416C21.6875 15.0987 21.1392 15.4153 20.9962 15.9487L20.9074 16.2801C20.4778 17.8837 21.4294 19.5319 23.0329 19.9616C24.6364 20.3912 26.2846 19.4396 26.7143 17.8361L26.8031 17.5047C26.946 16.9712 26.6294 16.4229 26.096 16.2799L22.221 15.2416Z"
                fill="#27272A"
              />
              <path
                d="M13.5055 16.2806C12.972 16.4236 12.6554 16.9719 12.7984 17.5054L12.8872 17.8368C13.3168 19.4403 14.9651 20.3919 16.5686 19.9623C18.1721 19.5326 19.1237 17.8844 18.694 16.2808L18.6052 15.9494C18.4623 15.416 17.914 15.0994 17.3805 15.2423L13.5055 16.2806Z"
                fill="#27272A"
              />
            </svg>
          </S.Pin>
          <S.Text>
            <h1>Oops!</h1>
            <h3>Sorry, an unexpected error has occurred.</h3>
          </S.Text>
          <S.BackLink to="/">
            <HomeIcon size={16} color="#27272A" />
            <span>Back to Pin</span>
          </S.BackLink>
          <S.ErrorCode>{children}</S.ErrorCode>
        </S.Content>
        <S.ContactLink href="mailto:support@pin.com">Contact Support</S.ContactLink>
      </S.Main>
    </S.ErrorLayout>
  )
}
