import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { EmptyState } from 'src/components/blocks/empty-state'
import { useState } from 'react'
import RouteBuilder from 'src/libs/route-builder'
import { CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'
import { useParams } from 'react-router-dom'
import { CandidatesTable } from '../candidates-table'
import { ExportToAtsButton, RejectButton, WriteEmailButton } from '../candidate-table-actions'
import { COLUMN } from '../candidates-table/table-builder'
import { useJobQuery } from 'src/hooks/queries/use-job'

export const CandidatesInCommunicationTable = (): JSX.Element => {
  const [rowSelection, setRowSelection] = useState({})
  const { jobId } = useParams()

  const { data: job } = useJobQuery()
  const { isPending, data: candidateJobs } = useCandidateJobsQuery({
    stage: CandidateJobStage.COMMUNICATING
  })

  return (
    <CandidatesTable
      visibleColumns={[COLUMN.FAVORITE, COLUMN.NAME, COLUMN.STATUS, COLUMN.LAST_CONTACT, COLUMN.SOURCE]}
      isLoading={isPending}
      candidateJobs={candidateJobs}
      setRowSelection={setRowSelection}
      rowSelection={rowSelection}
      emptyState={
        <EmptyState
          heading="No one in communication"
          description="Looks like you have no candidates you are communicating with. Once candidates respond to your sequence, they'll show up here."
          svg="messages"
          actions={[
            {
              href: RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId }),
              children: 'Go to sourcing'
            }
          ]}
        />
      }
      selectedRowsActions={
        <>
          <RejectButton
            selectedRows={rowSelection}
            onResetSelectedRows={() => {
              setRowSelection({})
            }}
          />
          <WriteEmailButton selectedRows={rowSelection} candidateJobs={candidateJobs} />
          <ExportToAtsButton selectedRows={rowSelection} candidateJobs={candidateJobs} job={job} />
        </>
      }
    />
  )
}
