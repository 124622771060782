import { useLocation, useNavigate, useParams } from 'react-router'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { useDialog } from 'src/hooks/use-dialog'
import { GroupLabel } from './group-label'
import { Trigger } from './trigger'
import { sleep } from 'src/libs/sleep'
import { useSession } from 'src/hooks/queries/use-session'
import { isNil } from 'lodash'
import { DialogId } from 'src/contexts/dialogs'
import { useJobByDepartmentsQuery } from 'src/hooks/queries/use-jobs-by-department'
import * as S from './org-jobs-dropdown.styled'
import { ChevronRight } from 'lucide-react'
import { Icons } from 'src/components/primitives/icon'
import { Badge } from 'src/components/primitives/badge'
import { Flex } from 'src/components/primitives/flex'
import { When } from '../when'
import { useJobStatsQuery } from 'src/hooks/queries/use-job-stats'
import { useCallback, useMemo } from 'react'
import { Button } from 'src/components/primitives/button'
import RouteBuilder from 'src/libs/route-builder'

interface OrgJobsDropdownProps {
  isCollapsed: boolean
}

const SPECIAL_ROUTES: [[RegExp, string]] = [
  [/\/inbox.*/, '/inbox/candidate_jobs']
]

const getTargetPath = (pathname: string, jobId: string | undefined, newJobId: string): string => {
  if (jobId && newJobId) {
    let newPath = pathname.replace(jobId, newJobId)
    for (const [regex, replacement] of SPECIAL_ROUTES) {
      if (regex.test(newPath)) {
        newPath = newPath.replace(regex, replacement)
        break
      }
    }
    return newPath
  }
  // Unlikely to reach here
  return RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId: newJobId })
}

export const OrgJobsDropdown = ({ isCollapsed }: OrgJobsDropdownProps): JSX.Element => {
  const navigate = useNavigate()
  const { data: session } = useSession()
  const { pathname } = useLocation()
  const { isPending, data: jobsByDepartment } = useJobByDepartmentsQuery()
  const { data: jobStats } = useJobStatsQuery()

  const numberOfTotalJobs = useMemo(() => {
    return jobsByDepartment ? Object.values(jobsByDepartment).flat().length : 0
  }, [jobsByDepartment])

  const numberOfActiveJobs = useMemo(() => {
    return jobsByDepartment ? Object.values(jobsByDepartment).flat().filter((job) => !job.deleted).length : 0
  }, [jobsByDepartment])

  const { jobId } = useParams()
  const { org, logoUrl: orgLogoUrl } = session ?? {}
  const { openDialog } = useDialog()

  const JOBS_COUNT_WHEN_TO_MAKE_CREATE_BUTTON_STICKY = 10

  const handleCreateNewJobClick = useCallback(async (): Promise<void> => {
    await sleep(10)
    // openDialog(DialogId.CREATE_JOB_POSITION)
    openDialog(DialogId.CREATE_NEW_JOB)
  }, [openDialog])

  const jobDropdownItems = useMemo((): MenuItemProps[] => {
    if (isNil(org)) {
      return []
    }
    if (isNil(jobsByDepartment)) {
      return []
    }

    const result = []
    const archivedResult = []
    for (const key of Object.keys(jobsByDepartment)) {
      const jobs = jobsByDepartment[key]
      if (isNil(jobs) || jobs.length === 0) {
        return []
      }

      const deptLabel = {
        id: key,
        title: <GroupLabel org={org} orgLogoUrl={orgLogoUrl} department={jobs[0].department} />,
        type: 'label'
      }

      const jobLabels = []
      const archivedJobLabels = []
      for (const job of jobsByDepartment[key]) {
        const numCandidatesAwaitingResponse = jobStats?.[job.id]?.numCandidatesAwaitingResponse ?? 0
        const hasDeactivatedSenders = jobStats?.[job.id]?.hasDeactivatedSenders ?? false
        const hasPausedSequence = jobStats?.[job.id]?.hasPausedSequence ?? false
        const jobLabel = {
          id: job.id,
          title: job.title,
          value: job.id,
          href: getTargetPath(pathname, jobId, job.id),
          trailingIcon: (
            <Flex $gap={6} $align="center" $justify="flex-end">
              <When condition={hasDeactivatedSenders}>
                <Button
                  nested
                  $variant="ghost"
                  $colorTheme="negative"
                  $width={12}
                  $height={12}
                  $fontSize={12}
                  leadingIcon="alert-triangle"
                  tooltip={{
                    text: 'Email account in outreach is disconnected',
                    position: 'right'
                  }}
                />
              </When>
              <When condition={hasPausedSequence}>
                <Button
                  nested
                  $variant="ghost"
                  $colorTheme="warning"
                  $width={12}
                  $height={12}
                  $fontSize={12}
                  leadingIcon="pause-circle"
                  tooltip={{
                    text: 'Outreach is paused',
                    position: 'right'
                  }}
                />
              </When>
              <When condition={numCandidatesAwaitingResponse > 0}>
                <Badge $variant="tint">{numCandidatesAwaitingResponse}</Badge>
              </When>
            </Flex>
          )
        }

        if (job.deleted) {
          archivedJobLabels.push({
            ...jobLabel,
            type: 'item',
            onSelect: async () => {
              navigate(jobLabel.href)
            }
          })
        } else {
          jobLabels.push(jobLabel)
        }
      }

      const jobsSeparator = {
        title: 'NewJobSeparator',
        type: 'separator'
      }

      if (jobLabels.length > 0) {
        result.push(deptLabel, ...jobLabels, jobsSeparator)
      }

      if (archivedJobLabels.length > 0) {
        archivedResult.push(deptLabel, ...archivedJobLabels, {
          title: 'ArchivedJobSeparator',
          type: 'separator'
        })
      }
    }

    if (numberOfActiveJobs <= JOBS_COUNT_WHEN_TO_MAKE_CREATE_BUTTON_STICKY) {
      result.push(
        {
          id: 'create-new-job',
          title: 'Create new job',
          onSelect: handleCreateNewJobClick,
          type: 'item',
          icon: Icons.plus,
          variant: 'tint',
          showActiveIcon: false
        }
      )
    }

    if (archivedResult.length > 0 && numberOfActiveJobs < JOBS_COUNT_WHEN_TO_MAKE_CREATE_BUTTON_STICKY) {
      result.push({
        title: 'NewJobSeparator',
        type: 'separator'
      })
    }

    if (archivedResult.length > 0) {
      result.push(
        {
          id: 'archived-jobs',
          title: 'Archived jobs',
          type: 'item',
          icon: Icons.archive,
          trailingIcon: <ChevronRight size="14" />,
          variant: 'muted',
          subitems: archivedResult.slice(0, -1) // we add a separator in at the end that we want to ignore
        }
      )
    }

    if (result.length > 0) {
      const lastItem = result[result.length - 1]
      if ('type' in lastItem && lastItem.type === 'separator') {
        result.pop()
      }
    }

    return result
  }, [handleCreateNewJobClick, jobId, jobStats, jobsByDepartment, navigate, numberOfActiveJobs, org, orgLogoUrl, pathname])

  if (isNil(org)) {
    return <></>
  }

  const jobs = !isNil(jobsByDepartment) ? Object.values(jobsByDepartment).flatMap((obj) => obj) : []

  const selectedJob = jobs.find((job) => job.id === jobId)

  return (
    <Flex $direction="column" $gap={6}>
      {/* <Flex $align="center" $justify="space-between">
        <Caption size="2XS" $color="fgSecondary">CURRENT JOB</Caption>
        <Button
          $variant="ghost"
          $colorTheme="tint"
          $height={18}
          $fontSize={10}
          onClick={handleCreateNewJobClick}
        >
          CREATE NEW
        </Button>
      </Flex> */}
      <S.OrgJobsDropdown $isCollapsed={isCollapsed}>
        <Dropdown
          trigger={
            <Trigger
              isCollapsed={isCollapsed}
              isLoading={isPending}
              selectedJob={selectedJob}
              logoUrl={orgLogoUrl}
            />
          }
          menuPosition="start"
          items={jobDropdownItems}
          stickyItem={
            numberOfActiveJobs >= JOBS_COUNT_WHEN_TO_MAKE_CREATE_BUTTON_STICKY + 1
              ? (
                  {
                    id: 'create-new-job',
                    title: 'Create new job',
                    onSelect: handleCreateNewJobClick,
                    type: 'item',
                    icon: Icons.plus,
                    variant: 'tint',
                    showActiveIcon: false
                  }
                )
              : undefined
          }
          selectedValue={jobId}
          $maxHeight="38rem"
          $minWidth='14.5rem'
          $maxWidth='20rem'
          allowFilter={numberOfTotalJobs >= 8}
          showActiveIcon
          activeIconPosition="start"
        />
      </S.OrgJobsDropdown>
    </Flex>
  )
}
