import { useParams } from 'react-router-dom'
import { exportCandidateJobsToCsv } from 'src/libs/api/backend/candidate_jobs'
import { useNotification } from './use-notification'

interface ReturnType {
  exportCandidates: () => Promise<void>
}

export const useExportCandidatesToCsv = (): ReturnType => {
  const { jobId } = useParams()
  const { notify } = useNotification()

  const exportCandidates = async (): Promise<void> => {
    try {
      await exportCandidateJobsToCsv({ jobId: jobId ?? '' })
      notify({
        type: 'toast',
        variant: 'positive',
        position: 'bottom-right',
        icon: 'check-check',
        message: 'Exported candidates into CSV file',
        autoClose: true
      })
    } catch (error) {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message:
          'Unfortunately, there was an error downloading this file. If they error occurs, please get in touch.'
      })
    }
  }

  return { exportCandidates }
}
