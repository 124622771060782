import { CandidateDetailsCard } from 'src/components/blocks/candidate-details-card'
import { CandidateActions } from 'src/components/blocks/candidate-actions'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { useDialog } from 'src/hooks/use-dialog'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { isNil } from 'lodash'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { useAddCandidateToSequence } from 'src/hooks/mutations/use-add-candidate-to-sequence'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useVirtualizer } from '@tanstack/react-virtual'
import { CANDIDATES_PAGES_MAX_WIDTH, CONTENT_PADDING } from 'src/styles/constants'
import { DialogId } from 'src/contexts/dialogs'
import { useDebounceCallback, useLocalStorage } from 'usehooks-ts'
import { LocalStorageKey, ViewMode } from 'src/constants'
import { CompaniesPreferencesProvider } from 'src/providers/companies-preferences'
import { useListJobSearchRefinementsQuery } from 'src/hooks/queries/use-job-search-refinements'
import { SourcingPageHeader, SourcingStatus } from 'src/components/blocks/sourcing'
import { When } from 'src/components/blocks/when'
import { SearchRefinementForm } from 'src/components/blocks/search-refinement-form'
import { useUpdateJobSearchRefinement } from 'src/hooks/mutations/use-update-job-search-refinement'
import queryClient from 'src/hooks/query-client'
import { queryKeys } from 'src/libs/query-keys'
import { useChannel } from 'ably/react'
import { useSession } from 'src/hooks/use-session'
import { CHANNEL_TYPE, getChannelName } from 'src/libs/api/backend/websockets'
import { JobSourcingState } from 'src/libs/api/backend/jobs'
import { isSequenceStepsEmpty } from 'src/libs/sequence'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
import { SEO } from '../../../components/primitives/seo'
import { generateUrlWithParams } from 'src/utils/format-url'
import RouteBuilder from 'src/libs/route-builder'
import { AutomateInfoBanner } from 'src/components/blocks/automate-info-banner'
import { Spacer } from 'src/components/primitives/spacer'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { useJobSearchRefinementSuggestedAdditions } from '../../../hooks/queries/use-job-search-refinement-suggested-additions'
import { DEFAULT_REFINEMENT_SUGGESTIONS } from '../../../libs/data'
import { useJobSearchRefinementSearchHistory } from '../../../hooks/queries/use-job-search-refinement-history'
// import { UpgradeCtaBanner } from 'src/components/blocks/upgrade-cta-banner'

const isSourcing = (sourcingState: string): boolean => {
  return sourcingState === JobSourcingState.REQUESTED || sourcingState === JobSourcingState.IN_PROGRESS
}

interface JobCandidatesSourcingPageProps {
  isManualSourceView?: boolean
}

const JobCandidatesSourcingPage = ({ isManualSourceView = false }: JobCandidatesSourcingPageProps): JSX.Element => {
  const { org, featureFlags } = useSession()
  const navigate = useNavigate()
  const { openDialog } = useDialog()
  const { jobId } = useParams()
  const [isFocused, setIsFocused] = useState(false)
  const { updateJobSearchRefinement } = useUpdateJobSearchRefinement()
  const { data: job } = useJobQuery()

  const [currViewMode, setViewMode] = useLocalStorage(LocalStorageKey.VIEW_MODE, ViewMode.DEFAULT)

  const [searchParams] = useSearchParams()
  const jobSearchRefinementId = searchParams.get('jobSearchRefinementId')
  const favorite = searchParams.get('favorite')
  const { data: jobSearchRefinements, isPending: jobSearchRefinementsPending } = useListJobSearchRefinementsQuery(jobId)
  const [isRecommending, setIsRecommending] = useState(false)
  useEffect(() => {
    // Auto navigate to the 1st search refinement if there is no search query and NOT a manual view
    if (!jobSearchRefinementsPending && searchParams.size === 0 && !isManualSourceView) {
      if (!isNil(jobSearchRefinements) && jobSearchRefinements.length > 0) {
        navigate(generateUrlWithParams(
          RouteBuilder.build('JOBS_CANDIDATES_SOURCING', {
            jobId: jobId ?? ''
          }), {
            jobSearchRefinementId: jobSearchRefinements[0].id
          }
        ))
      } else {
        navigate(RouteBuilder.build('JOBS_CANDIDATES_SOURCING_SEARCH', {
          jobId: jobId ?? ''
        }))
      }
    }
  }, [isManualSourceView, jobId, jobSearchRefinements, jobSearchRefinementsPending, navigate, searchParams])

  const jobSearchRefinement = useMemo(() => {
    return jobSearchRefinements?.find((refinement) => refinement.id === jobSearchRefinementId)
  }, [jobSearchRefinements, jobSearchRefinementId])

  const sourcingState = useMemo(() => {
    return jobSearchRefinement?.sourcingState
  }, [jobSearchRefinement?.sourcingState])

  const [renderedCandidates, setRenderedCandidates] = useState<CandidateJobExpanded[]>()

  const {
    isPending,
    data: candidateJobs
  } = useCandidateJobsQuery({
    stage: CandidateJobStage.SOURCED,
    jobSearchRefinementId,
    source: isManualSourceView ? null : undefined,
    favorite: !isNil(favorite) ? favorite === 'true' : undefined
  })
  const { isPending: isSequenceLoading, data: sequence } = useJobSequenceQuery()
  const { addCandidateToSequence } = useAddCandidateToSequence()

  const parentRef = useRef<HTMLDivElement>(null)

  const virtualizer = useVirtualizer({
    count: renderedCandidates?.length ?? 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 300,
    overscan: 5
  })

  useEffect(() => {
    setRenderedCandidates([])
    virtualizer.scrollToOffset(-100000)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobSearchRefinementId])

  const invalidateCandidateJobs = useCallback(() => {
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.job, jobId]
    })
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.candidateJobs, jobId]
    })
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.candidateJobCounts, jobId]
    })
  }, [jobId])

  const debouncedInvalidateCandidateJobs = useDebounceCallback(invalidateCandidateJobs, 2000)

  useChannel(getChannelName(CHANNEL_TYPE.CANDIDATE_SEARCH, org?.id ?? ''), (message) => {
    const { name, data } = message

    let matched = false
    if (jobSearchRefinementId) {
      if (name === jobSearchRefinementId) {
        if (
          jobSearchRefinement?.sourcingState !== JobSourcingState.COMPLETED ||
          isNil(jobSearchRefinement.title)
        ) {
          void queryClient.invalidateQueries({
            queryKey: [queryKeys.jobSearchRefinements, jobId]
          })
        }
        matched = true
      }
    }

    if (matched) {
      if (data?.sourcingState) {
        setIsRecommending(isSourcing(data.sourcingState as JobSourcingState))
        invalidateCandidateJobs()
      } else if (data?.candidateJobId) {
        debouncedInvalidateCandidateJobs()
      }
    }
  })

  useEffect(() => {
    if (candidateJobs) {
      const sourcedCandidates = candidateJobs?.filter((c) => c.stage === 'SOURCED')

      setRenderedCandidates(sourcedCandidates)
    }
  }, [candidateJobs])

  useEffect(() => {
    setIsRecommending(isSourcing(sourcingState ?? ''))
  }, [sourcingState])

  const maxWidth = CANDIDATES_PAGES_MAX_WIDTH

  const isEmpty = useMemo((): boolean => renderedCandidates?.length === 0, [renderedCandidates])

  const isArchived = useMemo((): boolean => Boolean(job?.deleted), [job?.deleted])

  const isLoading = useMemo(
    () => isPending || isSequenceLoading || isNil(job),
    [isPending, isSequenceLoading, job]
  )

  // TODO: This is a temporary fix to prevent jitter when opening up an
  // experience item. Ideally, this should be implemented when the item is
  // opened then reset, rather than just displaying it entirely here.
  virtualizer.shouldAdjustScrollPositionOnItemSizeChange = () => false

  const isSourcingView = useMemo(
    () => !!jobSearchRefinementId,
    [jobSearchRefinementId]
  )

  const updateJobRefinementOnClick = useCallback((searchQuery: string) => {
    setIsRecommending(true)
    updateJobSearchRefinement({
      jobId: jobId ?? '',
      jobSearchRefinementId: jobSearchRefinementId ?? '',
      searchQuery,
      automateAutoApproveCandidates: jobSearchRefinement?.automateAutoApproveCandidates,
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: [
            queryKeys.candidateJobs,
            jobId,
            {
              stage: CandidateJobStage.SOURCED,
              source: null,
              jobSearchRefinementId
            }
          ]
        })
        setRenderedCandidates([])
      }
    })
  }, [jobId, jobSearchRefinementId, updateJobSearchRefinement, jobSearchRefinement?.automateAutoApproveCandidates])

  const handleUpdateSearchRefinementTitle = (updatedTitle: string): void => {
    const existingSearchQuery = jobSearchRefinement?.searchQuery ?? ''
    updateJobSearchRefinement({
      jobId: jobId ?? '',
      jobSearchRefinementId: jobSearchRefinementId ?? '',
      title: updatedTitle,
      searchQuery: existingSearchQuery,
      automateAutoApproveCandidates: jobSearchRefinement?.automateAutoApproveCandidates
    })
  }

  const pageTitle = useMemo(() => {
    if (isManualSourceView) {
      return 'Manually Added'
    }

    if (jobSearchRefinement?.title) {
      return `Sourcing: "${jobSearchRefinement.title}"`
    }

    return 'Sourcing'
  }, [isManualSourceView, jobSearchRefinement?.title])

  const title = useMemo(() => {
    if (isManualSourceView) {
      return 'Sourcing · Manually Added'
    }
    if (jobSearchRefinement) {
      return jobSearchRefinement.title ?? 'Sourcing · New Search'
    }
    return 'Sourcing · All sourced'
  }, [isManualSourceView, jobSearchRefinement])

  const jobSearchRefinementSuggestedAdditions = useJobSearchRefinementSuggestedAdditions({
    jobId,
    jobSearchRefinementId
  })

  const { searchHistory } = useJobSearchRefinementSearchHistory({
    jobId: jobSearchRefinement?.jobId,
    jobSearchRefinementId: jobSearchRefinement?.id
  })

  if (isPending) {
    return (
      <div
        style={{
          width: '100%',
          maxWidth,
          padding: `${CONTENT_PADDING}`
        }}
      >
        <LoadingSkeleton $variant="CandidateDetailsCard" />
      </div>
    )
  }

  return (
    <>
      <SEO title={pageTitle} />

      <CompaniesPreferencesProvider key={`${jobId}-${jobSearchRefinementId}`}>
        <div
          ref={parentRef}
          style={{
            height: '100%',
            padding: `0 ${CONTENT_PADDING}`,
            overflow: isFocused ? 'hidden' : 'auto',
            contain: 'strict',
            display: 'flex',
            flexDirection: 'column',
            scrollBehavior: 'smooth',
            position: 'relative'
          }}
        >
          {isFocused && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'hsla(240, 20%, 99%, 0.5)',
                zIndex: 2
              }}
            />
          )}
          <SourcingPageHeader
            headingLoading={jobSearchRefinement && !jobSearchRefinement.title}
            showActions={!isArchived && !jobSearchRefinementId}
            title={title}
            isEditable={(jobSearchRefinement?.title?.length ?? 0) >= 1}
            onEdit={(updatedTitle) => {
              handleUpdateSearchRefinementTitle(updatedTitle)
            }}
            setViewMode={setViewMode}
            currViewMode={currViewMode}
            jobId={jobId}
            jobSearchRefinementId={jobSearchRefinementId}
            candidateJobIds={renderedCandidates?.map((c) => c.id) ?? []}
            isSequenceEmpty={isSequenceStepsEmpty(sequence)}
          />
          <When condition={!isNil(jobSearchRefinement?.id)}>
            <div
              style={{
                width: '100%',
                maxWidth,
                marginBottom: 20
              }}
            >
              <When condition={!job?.deleted}>
                <SearchRefinementForm
                  onSubmit={(searchQuery) => {
                    updateJobRefinementOnClick(searchQuery)
                  }}
                  isSearching={isRecommending}
                  pickedRecommendation={jobSearchRefinement?.searchQuery}
                  highlights={jobSearchRefinement?.highlights}
                  setIsFocused={setIsFocused}
                  isFocused={isFocused}
                  suggestions={jobSearchRefinementSuggestedAdditions ?? DEFAULT_REFINEMENT_SUGGESTIONS}
                  searchHistoryItems={searchHistory}
                />
                <Spacer $size={20} />
                {featureFlags?.includes(FeatureFlags.AI_AUTOMATION) &&
                  <AutomateInfoBanner />
                }
              </When>
            </div>
          </When>
          <div
            style={{
              minHeight: virtualizer.getTotalSize(),
              position: 'relative'
            }}
          >
            {virtualizer.getVirtualItems().map((virtualRow) => {
              const candidateJob = renderedCandidates?.[virtualRow.index]
              if (isNil(candidateJob)) {
                return <></>
              }
              return (
                <div
                  style={{
                    width: '100%',
                    maxWidth,
                    position: 'absolute',
                    top: `${virtualRow.start}px`
                  }}
                  key={virtualRow.key}
                  data-index={virtualRow.index}
                  ref={virtualizer.measureElement}
                >
                  <CandidateDetailsCard
                    key={candidateJob.id}
                    stage={candidateJob.stage}
                    candidate={candidateJob.candidate}
                    candidateJob={candidateJob}
                    actions={
                      <CandidateActions
                        candidateJob={candidateJob}
                        onAddToSequenceClick={() => {
                          if (isSequenceStepsEmpty(sequence)) {
                            openDialog(DialogId.CREATE_SEQUENCE)
                          } else {
                            addCandidateToSequence([candidateJob.id])
                          }
                        }}
                      />
                    }
                    viewMode={currViewMode}
                  />
                </div>
              )
            })}
          </div>
          {/*
          <UpgradeCtaBanner
            heading="10k other candidates found"
            benefits={[
              'Unlock this premium feature for unlimited results',
              'Auto-outreach to new candidates daily',
              'Get more meetings with high-quality candidates'
            ]}
            addOn="AI Sourcing"
            addOnDescription="Add AI sourcing to your open job positions."
            addOnPrice={99}
          />
          */}
          <SourcingStatus
            isRecommending={isRecommending}
            isEmpty={isEmpty}
            lastRequestedAt={jobSearchRefinement?.lastSourcingRequestedAt ?? new Date()}
            isSourcingView={isSourcingView}
            isManualSourceView={isManualSourceView}
            sourcingState={sourcingState}
            isLoading={isLoading}
            isArchived={isArchived}
          />
        </div>
      </CompaniesPreferencesProvider>
    </>
  )
}

export default JobCandidatesSourcingPage
